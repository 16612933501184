import _get from "lodash/get";
import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {
  getAllDvir,
  getAllDvirCount,
  getDriverDvir,
  deleteDvir
} from "../../actions/dvirActions";
import {
  getStartOfDay,
  getEndOfDay,
  formatDateTime,
} from "../../actions/momentActions";
import { startDay, currTime } from "../../utils/momentHelpers";
import DvirTable from "./DvirTable";
import AddDvirDialog from "./AddDvirDialog";
import SearchBar from "../../components/SearchBar";

export class Register extends React.Component {
  state = {
    limit: 10,
    page: 0,
    loading: false,
    isAddDvir: false,
    start: startDay,
    end: currTime,
    driverId: "",
    vehicleId: "",
  };

  async componentDidMount() {
    const roles = _get(this, "props.profile.roles");

    if (!roles.includes("DRIVER")) {
      await this.props.getAllDvirCount({});
    }

    await this.getAllDvir({});
  }

  getAllDvir = async () => {
    this.setState({ loading: true });
    const { limit, page, driverId, start, end, vehicleId } = this.state;
    const skip = parseInt(page) * parseInt(limit);

    const roles = _get(this, "props.profile.roles");

    if (roles.includes("DRIVER")) {
      await this.props.getDriverDvir({
        limit,
        skip,
        driverId,
        vehicleId,
        start: start ? new Date(this.props.getStartOfDay(start)) : null,
        end: end ? new Date(this.props.getEndOfDay(end)) : null,
      });
    } else {
      await this.props.getAllDvir({
        limit,
        skip,
        driverId,
        vehicleId,
        start: start ? new Date(this.props.getStartOfDay(start)) : null,
        end: end ? new Date(this.props.getEndOfDay(end)) : null,
      });
    }
    this.setState({ loading: false });
  };

  handleChange = ({ limit, page }) => {
    this.setState({ limit, page }, this.getAllDvir);
  };

  handleSearchChange = ({ driverId, vehicleId, start, end }) => {
    this.setState({ driverId, vehicleId, start, end }, this.getAllDvir);
  };

  onSearch = ({ driverId, start, end }) => {
    this.setState({ driverId, start, end }, this.getAllDvir());
  };

  render() {
    const { dvirs } = this.props;
    const { loading, isAddDvir } = this.state;
    const roles = _get(this, "props.profile.roles");

    return (
      <Grid container spacing={0} style={{ padding: 10 }}>
        <Grid item xs={12} sm={12}>
          {!roles.includes("DRIVER") && (
            <SearchBar
              options={{
                isEqp: true,
                isTrailer: true,
                isDriver: true,
                isStartDate: true,
                isEndDate: true,
                isTime: true,
                isTimeperiod: true,
                allDrivers: true,
              }}
              dl={true}
              loading={loading}
              onSearch={this.handleSearchChange}
            />
          )}
          {loading && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          )}
          {isAddDvir && (
            <AddDvirDialog
              open={isAddDvir}
              handleClose={() => this.setState({ isAddDvir: false })}
              afterSave={() => {
                this.setState({ isAddDvir: false });
                this.getAllDvir();
              }}
            />
          )}
          {roles.includes("DRIVER") && (
            <div style={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => this.setState({ isAddDvir: true })}
              >
                {"Add"}
              </Button>
            </div>
          )}

          <DvirTable
            items={dvirs}
            count={this.props.count}
            getAllDvir={this.getAllDvir}
            formatDateTime={this.props.formatDateTime}
            limit={this.state.limit}
            page={this.state.page}
            handleChange={this.handleChange}
            adminKey={this.props.adminKey}
            deleteDvir={this.props.deleteDvir}
            accountBaseUrl={this.props.accountBaseUrl}
          />
        </Grid>
      </Grid>
    );
  }
}

reduxForm({
  form: "RegisterForm",
})(Register);

const mapStateToProps = (state) => {
  return {
    dvirs: state.dvirData.dvirAll,
    count: state.dvirData.count,
    profile: _get(state, "authData.profile"),
    adminKey: _get(state, "authData.profile.adminId"),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllDvir,
      getStartOfDay,
      getEndOfDay,
      formatDateTime,
      getAllDvirCount,
      getDriverDvir,
      deleteDvir
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Register)
);
