import _get from "lodash/get";
import { isSameDay } from "../../utils/momentHelpers";
import { LABELS as _LABELS } from "../../language";

const LABELS = _LABELS.dutyPage;

function pF(value) {
  return parseFloat(value) ? parseFloat(value) : 0;
}

export function getCoDriver(props) {
  const selectedDate = props.selectedDate || "";
  const baseUrl = props.accountBaseUrl || "";
  const coDrivers = _get(props, "dailyDiary[0].coDriver", []);

  let all = {};
  if (coDrivers && Array.isArray(coDrivers)) {
    coDrivers.forEach((item, i) => {
      let fullName = item.firstName ? `${item.firstName} ${item.lastName}` : "";
      // Add comma only for the first full name
      if (fullName && fullName !== "null") {
        if (i === 0) {
          fullName += ", ";
        }
        all[fullName] = {
          id: item.id || item._id,
          name: fullName,
        };
      }
    });
  }

  const url = `${baseUrl}/status/detail`;
  return Object.values(all).map((item) => (
    <span key={item.name}>
      {props.role === "DRIVER" ? (
        item.name
      ) : (
        <a
          style={{ padding: 0, color: "#009be5", textDecoration: "none" }}
          href={`${url}/${item.id}?selectedDate=${selectedDate}`}
        >
          {item.name}
        </a>
      )}
    </span>
  ));
}

export function getVehicleDriven(props) {
  const { driverLogs = [], dailyDiary = [] } = props;
  const diaryVehicles = _get(dailyDiary[0], "vehicle", []);
  let vehicle = "";
  diaryVehicles.forEach((item, index) => {
    if (index === 0) {
      vehicle = vehicle + "" + item.name;
    } else {
      vehicle = vehicle + "," + item.name;
    }
  });

  let all = {};
  driverLogs.forEach((item) => {
    const name = item.vehicle && item.vehicle.name;
    // const vin = item.vehicle && item.vehicle.vin;
    if (name && name !== "null") {
      all[name] = `${name} `;
    }
  });

  return vehicle ? (
    <span>{vehicle}</span>
  ) : (
    Object.values(all).map((item) => <span key={item}>{item}</span>)
  );
}

export function getTrailerDriven(props) {
  const { driverLogs = [] } = props;

  let all = [];
  driverLogs.forEach((item) => {
    const name = item.trailer;
    if (name && name !== "null" && item.recordStatus === "ACTIVE") {
      if (!all.includes(name)) {
        all.push(name);
      }
    }
  });
  return all.join(", ");
}

export function getShippingDocs(props) {
  const { driverLogs = [] } = props;
  let all = [];
  driverLogs.forEach((item) => {
    const name = item.shippingDoc;
    if (name && name !== "null" && item.recordStatus === "ACTIVE") {
      if (!all.includes(name)) {
        all.push(name);
      }
    }
  });
  return all.join(", ");
}

export function getEngineHours(props) {
  try {
    const selectedDate = new Date(props.selectedDate);
    const allLogs =
      Array.isArray(props.driverLogs) &&
      props.driverLogs.filter((item) => {
        return (
          item.recordStatus === "ACTIVE" &&
          (item.eventType === "CHANGE_DUTY_STATUS" ||
            item.eventType === "YARD_MOVES" ||
            item.eventType === "PERSONAL_USE") &&
          item.odometer !== 0
        );
      });
    let startEvt;
    let lastEvt;
    let start = 0;
    let end = 0;
    for (let i = 0; i < allLogs.length; i++) {
      const current = allLogs[i] || {};
      const eventTime = current.eventTimeOrg || current.eventTime;

      // if (isSameDay(eventTime, selectedDate)) {
      let valid = current.engineHours ? parseFloat(current.engineHours) : 0;
      if (valid) {
        start = valid;
        break;
      }
      // }
    }

    for (var i = allLogs.length; i--; ) {
      const current = allLogs[i] || {};
      const eventTime = current.eventTimeOrg || current.eventTime;

      // if (isSameDay(eventTime, selectedDate)) {
      let valid = current.engineHours ? parseFloat(current.engineHours) : 0;
      if (valid) {
        end = valid;
        break;
      }
      // }
    }

    const diff = parseFloat(end) - parseFloat(start);

    if (diff > 0) {
      return Math.round(diff * 100) / 100;
    } else {
      return `0`;
    }

    // const startHr = (start && start.toString().split(".")[0]) || 0;
    // const startMin = (start && start.toString().split(".")[1]) || 0;
    // const startMins = parseInt(startMin) + parseInt(startHr) * 60;
    //
    // const endHr = (end && end.toString().split(".")[0]) || 0;
    // const endMin = (end && end.toString().split(".")[1]) || 0;
    // const endMins = parseInt(endMin) + parseInt(endHr) * 60;
    //
    // const minutes = endMins - startMins;
    // let hrs = Math.floor(minutes / 60);
    // let mins = minutes - hrs * 60;
    //
    // if (minutes > 1440 || minutes < 1) {
    //   return `00 Hours`;
    // }
    //
    // if (mins.toString().length === 1) {
    //   mins = `0${mins}`;
    // }
    // if (hrs.toString().length === 1) {
    //   hrs = `0${hrs}`;
    // }
    // return `${hrs}:${mins} Hours`;
  } catch (e) {
    return `00:00 Hours`;
  }
}

export function getOdometer(props) {
  const allLogs =
    Array.isArray(props.driverLogs) &&
    props.driverLogs.filter((item) => {
      return (
        item.recordStatus === "ACTIVE" &&
        (item.eventType === "CHANGE_DUTY_STATUS" ||
          item.eventType === "YARD_MOVES" ||
          item.eventType === "PERSONAL_USE") &&
        item.odometer !== 0
      );
    });

  let totalMiles = 0;
  for (let i = 0; i < allLogs.length; i++) {
    const current = allLogs[i] || {};
    let obj = {};

    if (current.eventCode === "DRIVING") {
      for (let j = i; j < allLogs.length; j++) {
        const current2 = allLogs[j] || {};
        const ensStatus = ["ONDUTY_ND", "SLEEPER", "OFFDUTY"];
        if (ensStatus.includes(current2.eventCode)) {
          let miles = parseInt(current2.odometer) - parseInt(current.odometer);
          totalMiles = miles > 0 ? totalMiles + miles : totalMiles;
          break;
        }
      }
    }
  }

  return `${Math.round(totalMiles)} Miles`;
}

export function getUnidentifiedDriving() {
  return "Unidentified Driving Time = 00:00:00";
}

export function getTerminal(props) {
  const selectedDriver = props.selectedDriver || {};
  const terminal = selectedDriver.terminal || {};
  const line1 = terminal.line1 ? `${terminal.line1}, ` : "";
  const city = terminal.city ? `${terminal.city}, ` : "";
  const state = terminal.state ? `${terminal.state}` : "";
  // const zip = terminal.zip ? terminal.zip : "";

  return `${line1} ${city} ${state}`;
  // return terminal.name ? terminal.name : "";
}

export function getTerminalAddress(props) {
  const selectedDriver = props.selectedDriver || {};
  const terminal = selectedDriver.terminal || {};
  const line1 = terminal.line1 ? `${terminal.line1}, ` : "";
  const city = terminal.city ? `${terminal.city}, ` : "";
  const state = terminal.state ? `${terminal.state}` : "";
  // const zip = terminal.zip ? terminal.zip : "";

  return `${line1} ${city} ${state}`;
}

export function getInfoArray(props) {
  const selectedDate = props.selectedDate;
  const formatRawDate = props.formatRawDate;
  const selectedDriver = props.selectedDriver || {};
  const driver = selectedDriver.driver || {};
  const carrier = selectedDriver.carrier || {};
  const timezone = _get(selectedDriver, "terminal.timezone");
  const cycle = _get(props, "cycleData.cycleUsa");
  const android = _get(props, "dailyDiary[0].androidSoftwareVersion", "");
  const androidOS = _get(props, "dailyDiary[0].androidOsVersion", "");
  const ios = _get(props, "dailyDiary[0].iosSoftwareVersion", "");
  const iosOS = _get(props, "dailyDiary[0].iosOsVersion", "");

  const seperator = android && ios ? ", " : "";

  const appConfigAndroid = props.appConfig?.android || {};
  const appConfigIos = props.appConfig?.ios || {};

  let color = "black";

  if (!android) {
    if (parseFloat(ios) <= parseFloat(appConfigIos.outdated)) {
      color = "red";
    } else if (parseFloat(ios) < parseFloat(appConfigIos.current)) {
      color = "orange";
    }
  } else {
    if (parseFloat(android) <= parseFloat(appConfigAndroid.outdated)) {
      color = "red";
    } else if (parseFloat(android) < parseFloat(appConfigAndroid.current)) {
      color = "orange";
    }
  }
  const cycleSel = (cycle && driver.cycleUsa) || "";
  const cargo = driver.cargo === "PASSENGER" ? "(Passenger)" : "";
  const shipDoc = getShippingDocs(props);
  const trailer = getTrailerDriven(props);

  const allItems = [
    {
      label: LABELS.DATE,
      value: formatRawDate(selectedDate),
      labelMiddle: LABELS.START_TIME,
      valueMiddle: `${driver.startTime || ""} ${timezone || ""}`,
      labelRight: LABELS.CYCLE,
      valueRight: `${cycleSel}${cargo}`,
    },
    {
      label: LABELS.DRIVER,
      value: `${selectedDriver.firstName || ""} ${
        selectedDriver.lastName || ""
      }`,
      labelMiddle: LABELS.CARRIER,
      valueMiddle: `${carrier.name || ""}`,
      labelRight: LABELS.CO_DRIVERS,
      valueRight: getCoDriver(props),
    },
    {
      label: LABELS.VEHICLES,
      value: getVehicleDriven(props),
      labelMiddle: LABELS.HOMETERMINAL,
      valueMiddle: getTerminal(props),
      valueMiddleTwo: getTerminalAddress(props),
      labelRight: LABELS.DISTANCE,
      valueRight: getOdometer(props),
    },

    {
      label: LABELS.TRAILERS,
      value: getTrailerDriven(props),
      error: !trailer,
      labelMiddle: LABELS.SHIPPING_DOCS,
      labelMiddleLink: true,
      valueMiddle: shipDoc,
      errorMiddle: !shipDoc,
      labelRight: LABELS.ENGINE_HOURS,
      valueRight: getEngineHours(props),
    },
    {
      label: LABELS.EMAIL,
      value: (
        <span style={{ textTransform: "none" }}>
          {selectedDriver.email || ""}
        </span>
      ),
      labelMiddle: LABELS.app,
      valueMiddleStyle: { color: color },
      valueMiddle: `${ios}${seperator} ${android}`,
      labelRight: LABELS.device,
      valueRight: `${iosOS}${seperator} ${androidOS}`,
    },
  ];

  return allItems;
}

export function getInfo(props) {
  const selectedDate = props.selectedDate;
  const formatRawDate = props.formatRawDate;
  const selectedDriver = props.selectedDriver || {};
  const driver = selectedDriver.driver || {};
  const carrier = selectedDriver.carrier || {};
  const timezone = _get(selectedDriver, "terminal.timezone");
  const cycle = _get(props, "cycleData.cycleUsa");
  const android = _get(props, "dailyDiary[0].androidSoftwareVersion", "");
  const androidOS = _get(props, "dailyDiary[0].androidOsVersion", "");
  const ios = _get(props, "dailyDiary[0].iosSoftwareVersion", "");
  const iosOS = _get(props, "dailyDiary[0].iosOsVersion", "");

  const seperator = android && ios ? ", " : "";

  const appConfigAndroid = props.appConfig?.android || {};
  const appConfigIos = props.appConfig?.ios || {};

  let color = "black";

  if (!android) {
    if (parseFloat(ios) <= parseFloat(appConfigIos.outdated)) {
      color = "red";
    } else if (parseFloat(ios) < parseFloat(appConfigIos.current)) {
      color = "orange";
    }
  } else {
    if (parseFloat(android) <= parseFloat(appConfigAndroid.outdated)) {
      color = "red";
    } else if (parseFloat(android) < parseFloat(appConfigAndroid.current)) {
      color = "orange";
    }
  }

  const allItems = {
    date: {
      today: formatRawDate(selectedDate),
      startTime: driver.startTime,
      timeZone: timezone,
      cycle: `${(cycle ? cycle : driver.cycleUsa) || ""}`,
    },
    driver: {
      firstName: `${selectedDriver.firstName || ""}`,
      lastName: `${selectedDriver.lastName || ""}`,
      email: selectedDriver.email,
    },
    coDriver: {
      name: getCoDriver(props),
    },
    carrier: {
      name: `${carrier.name || ""}`,
    },
    vehicle: {
      name: getVehicleDriven(props),
    },
    terminal: {
      name: getTerminal(props),
      address: getTerminalAddress(props),
    },
    odometer: {
      name: getOdometer(props),
    },
    trailer: {
      name: getTrailerDriven(props),
    },
    shippingDocs: {
      name: getShippingDocs(props),
    },
    engineHours: {
      name: getEngineHours(props),
    },
    app: {
      ios: ios,
      android: android,
      deviceIos: iosOS,
      deviceAnd: androidOS,
    },
  };

  return allItems;
}
