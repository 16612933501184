import _get from "lodash/get";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import NotFoundTableRow from "../NotFoundTableRow";
import {LABELS as _LABELS} from "../../language";

const LABELS =_LABELS.settingPage

const styles = theme => ({
    root: {
        width: "98%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: theme.spacing(1),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    tableRowName: {
        minWidth: 200
    },
    tableRowRoute: {
        minWidth: 100
    },
    tableRowVehicle: {
        minWidth: 160
    },
    tableRowAddress: {
        minWidth: 200
    },
    tableRowDate: {
        minWidth: 210
    },
    tableHead: {
        padding: 5,
        minWidth: 100
    },
    tableRowTitle: {
        display: "inline-block",
        width: 95
    },
    capitalize: {
        textTransform: "capitalize"
    },
    EditIcon: {
        color: "#009be5",
        cursor: "pointer"
    }
});

class ReduxTableRow extends React.Component {
    render() {
        const { classes, item = {} } = this.props;
        // const speed = _get(item, "telemetrics.speed", 0);

        const speed = _get(item, "telemetrics.speedKm", 0);
        const conversionFactor=0.621371   // conversion from km/hr  to Mi/hr;

        const speedMi=(speed*conversionFactor).toFixed(2);

        // const odometer = _get(item, "telemetrics.odometer", 0);

        // const mEngineAge = item.rawData.mEngineAge
        // let rawData = {};
        // try {
        //     rawData = JSON.parse(item.rawData);
        // } catch (e) {}
        // const mVelocity = rawData.mVelocity;
        // const mSeq = rawData.mSeq;
        // const mIsLive = rawData.mIsLive;
        // const mRpm = rawData.mRpm;
        // const mOdometer = rawData.mOdometer;
        // const mEngineHours = rawData.mEngineHours;

        // console.log(rawData);
        //
        // const tets = {
        //     mDateTime: {
        //         date: "20210320",
        //         time: "190142"
        //     },
        //     mEngineAge: 999,
        //     mEngineHours: 0.95,
        //     mEvent: "EV_PERIODIC",
        //     mGeoloc: {
        //         dop: 1.1,
        //         gpsage: 1,
        //         heading: 190,
        //         isLocked: true,
        //         latitude: 30.708981,
        //         longitude: 76.683806,
        //         satCount: 7,
        //         speed: 1.7212501006724779e-6,
        //         speedMi: 1.0695348963049582e-6
        //     },
        //     mIsLive: false,
        //     mObd2: 0,
        //     mOdometer: 100124.62,
        //     mRpm: 6023,
        //     mSeq: 1260,
        //     mVelocity: 250
        // };

        return (
            <TableRow hover>
                <TableCell>
                    <div className={classes.capitalize}>
                        {item.driver && (
                            <div>
                                {item.driver.firstName} {item.driver.lastName}
                            </div>
                        )}
                    </div>
                </TableCell>
                <TableCell>
                    <div className={classes.capitalize}>
                        {item.vehicle && <div>{item.vehicle.name}</div>}
                    </div>
                </TableCell>
                {/* <TableCell>
                {speed && (
                        <div>{speed ? parseInt(speed) : 0} Mi/Hr</div>
                    )}
                </TableCell> */}
                <TableCell>
                    {speedMi && (
                        <div>{speedMi ? speedMi :0} Mi/Hr</div>
                    )}
                </TableCell>
                <TableCell>
                    {item.address && (
                        <div
                            className={`${classes.capitalize} ${classes.tableRowAddress
                                }`}
                        >
                            {item.address.line1 && (
                                <div>{item.address.line1}</div>
                            )}

                            {item.address.state && (
                                <div>
                                    {`${item.address.city} `}
                                    {`${item.address.state}`}
                                    {item.address.zip && (
                                        <span>{`-${item.address.zip}`}</span>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </TableCell>
                <TableCell>
                    <div className={classes.tableRowDate}>
                        {this.props.formatDateTimeSec(item.time || item.created)}
                    </div>
                </TableCell>
                <TableCell>

                </TableCell>
                {/*<TableCell>
                    <div>mVelocity: {mVelocity}</div>
                    <div>mSeq: {mSeq}</div>
                    <div>mIsLive: {mIsLive}</div>
                    <div>mRpm: {mRpm}</div>
                    <div>mOdometer: {mOdometer}</div>
                    <div>mEngineHours: {mEngineHours}</div>

                    <div>{"item.rawData"}</div>
                </TableCell>*/}
            </TableRow>
        );
    }
}

export class ReduxTable extends React.Component {
    state = {
        order: "asc"
    };

    handleChangePage = (event, page) => {
        const { limit } = this.props;
        this.props.handleChange({ page, limit });
    };

    handleChangeRowsPerPage = event => {
        const limit = event.target.value;
        const { page } = this.props;
        this.props.handleChange({ page, limit });
    };

    render() {
        const { classes, items = [], count = 1000 } = this.props;
        const { page, limit } = this.props;
        // const {order} = this.state;

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableRowName}>
                                {LABELS.name}
                            </TableCell>
                            <TableCell className={classes.tableRowVehicle}>
                                {LABELS.vehicle}
                            </TableCell>
                            <TableCell className={classes.tableRowVehicle}>
                                {LABELS.speed}
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                {LABELS.location}
                            </TableCell>
                            <TableCell className={classes.tableHead}>
                                {LABELS.date}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(items) &&
                            items.map((item, i) => (
                                <ReduxTableRow
                                    item={item}
                                    key={i}
                                    classes={classes}
                                    handleEdit={this.props.handleEdit}
                                    formatDate={this.props.formatDate}
                                    formatTime={this.props.formatTime}
                                    // handleTracking={this.props.handleTracking}
                                    formatDateTimeSec={
                                        this.props.formatDateTimeSec
                                    }
                                />
                            ))}
                    </TableBody>
                </Table>
                <NotFoundTableRow items={items} />
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 500]}
                    component="div"
                    count={count}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                    labelRowsPerPage={LABELS.rowsperpage}
                />
            </Paper>
        );
    }
}

ReduxTable.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ReduxTable);
