import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import {
  isToday,
  isYesterday,
  formatUsDate,
  formatTime,
  formatTimeSec,
} from "../../actions/momentActions";
import WhatsappTable from "./WhatsappTable";
import ChatUser from "./ChatUser";
import ChatMessage from "./ChatMessage";
import {
  getMessages,
  getChats,
  getMedia,
  replyChat,
  chatRead,
} from "../../actions/adminWhatsappActions";

const styles = (theme) => ({
  addWrapper: {
    padding: 10,
  },
  searchText: {
    display: "inline-block",
    marginTop: 10,
    marginLeft: 5,
  },
});

export class Reseller extends React.Component {
  state = {
    chats: [],
    messages: [],
    selected: {},
    loading: false,
    loadingMsg: false,
    sending: false,
  };

  async componentDidMount() {
    this.getChats();
    this.timer = setInterval(() => this.getChats(), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  getChats = async () => {
    this.setState({ loading: false });
    const chats = await this.props.getChats({});
    this.setState({ chats, loading: false });

    const selected = this.state.selected;
    if (selected && selected._id) {
      const messages = await this.props.getMessages({
        phoneFrom: selected.phoneFrom,
        phoneTo: selected.phoneTo,
      });
      this.setState({ messages });
    }
  };

  handleChatClick = async (selected) => {
    this.setState({ loadingMsg: true, selected, messages: [] });
    this.loadMessages(selected);
  };

  loadMessages = async (selected) => {
    const messages = await this.props.getMessages({
      phoneFrom: selected.phoneFrom,
      phoneTo: selected.phoneTo,
    });
    this.handleChatRead(selected);
    this.setState({ messages, loadingMsg: false });
  };

  handleChatRead = async (selected) => {
    if (selected && !selected.read) {
      return await this.props.chatRead({
        id: selected._id,
      });
    }
  };

  handleChatReply = async (params) => {
    const response = await this.props.replyChat(params);
    this.getChats();
    this.loadMessages(this.state.selected);
    return response;
  };

  handleMessageClick = async (message) => {};

  render() {
    const classes = this.props.classes;
    const { loading, loadingMsg, sending } = this.state;
    const selected = this.state.selected || {};
    const chats = this.state.chats || [];
    const messages = this.state.messages || [];
    const maxHeight = 700;

    return (
      <div>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <Grid container className={classes.addWrapper}>
            <Grid item xs={12} sm={12} md={4}>
              <ChatUser
                items={chats}
                maxHeight={maxHeight}
                selected={this.state.selected}
                isToday={this.props.isToday}
                formatTime={this.props.formatTime}
                formatUsDate={this.props.formatUsDate}
                isYesterday={this.props.isYesterday}
                handleClick={this.handleChatClick}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              <ChatMessage
                maxHeight={maxHeight}
                items={messages}
                loadingMsg={loadingMsg}
                selected={this.state.selected}
                getMedia={this.props.getMedia}
                isToday={this.props.isToday}
                formatTime={this.props.formatTime}
                formatUsDate={this.props.formatUsDate}
                formatTimeSec={this.props.formatTimeSec}
                isYesterday={this.props.isYesterday}
                handleClick={this.handleMessageClick}
                // getMedia={this.props.getMedia}
                handleChatRead={this.handleChatRead}
                handleChatReply={this.handleChatReply}
              />
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isToday,
      isYesterday,
      formatUsDate,
      formatTime,
      getMessages,
      getChats,
      getMedia,
      chatRead,
      replyChat,
      formatTimeSec,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Reseller)
);
