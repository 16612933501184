import React, { useState, useEffect } from "react";
import _get from "lodash/get";
import dayjs from "dayjs";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select  from "@mui/material/Select";
// import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
// import MomentUtils from "@date-io/moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { getDrivers } from "../../actions/driverActions";
import { getEquipments } from "../../actions/equipmentActions";
import { getAccounts } from "../../actions/account/accountAdminActions";
import {
  formatDate,
  formatTime,
  formatDateTimeSec,
  formatDateTimeUtc,
  getStartOfDay,
  getEndOfDay,
} from "../../actions/momentActions";
import { isPastDate, searchIftaYear } from "./searchHelpers";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";
import { LABELS as _LABELS } from "../../language";
import SummarizeIcon from "@mui/icons-material/Summarize";
import DownloadIcon from "@mui/icons-material/Download";

const LABELS = _LABELS.adminPage;
const LABEL = _LABELS.settingPage;

const IFTA_YEARS = searchIftaYear(2);
const styles = (theme) => ({});

const SearchBar = (props) => {
  let {
    // classes,
    accountId,
    constants,
    trackingId,
    drivers = [],
    loading = false,
    stateMil = false,
    // tracking = false,
    options: {
      isEqp = false,
      isTrailer = false,
      isDriver = false,
      isState = false,
      isDownload = false,
      isDownloadIcon = false,
      isStartDate = false,
      isEndDate = false,
      isTime = false,
      isPrefix = false,
      isTimeperiod = false,
      isReport = false,
      isDetail = false,
      // formatDate = "MM/DD/yyyy",
      // format = "MM/DD/yyyy hh:mm a",
      downloadLabel = "DOWNLOAD",
      ...rest
    } = {},
    dl,
    startLabel,
    // endLabel,
  } = props;

  dl = dl || rest.dl;

  const allDrivers = props.options.allDrivers;
  const allStates = constants.US_STATES;
  const _equipments = props.equipments || [];
  const equipments = isTrailer
    ? _equipments
    : _equipments.filter((item) => item.assetType === "VEHICLE");
  const [stateId, setState] = useState("");
  const [driverId, setDriver] = useState("");
  const [prefix, setPrefix] = useState(1);
  const [vehicleId, setVehicle] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [iftaYears, setIftaYears] = useState();

  const [timeperiod, setTimeperiod] = useState("");
  const [view, setView] = useState("Map");

  let isValid = true;

  if (isStartDate && !startDate) {
    isValid = false;
  }
  if (isEndDate && !endDate) {
    isValid = false;
  }

  // if (tracking) {
  //     setStartDate(moment().startOf('day'))
  //     setEndDate(currTime)

  // }

  useEffect(() => {
    async function fetchData() {
      let payloadDrivers = { skip: 0, limit: 250 };
      if (!allDrivers) {
        payloadDrivers.status = "ACTIVE";
      }
      await props.getDrivers(payloadDrivers);
      await props.getEquipments({ skip: 0, limit: 250, active: true });
    }
    const startOfDay = props.formatDateTimeUtc(moment().startOf("day"));
    const endOfDay = props.formatDateTimeUtc(moment());
    setStartDate(startOfDay);
    setEndDate(endOfDay);

    fetchData();
    if (trackingId) {
      setDriver(trackingId);
    }

    // eslint-disable-next-line
  }, [accountId, trackingId]);

  const onSearch = (e, reportOn) => {
    e.preventDefault();
    let payload = {
      reportOn,
    };
    if (isDriver) {
      payload.driverId = driverId;
    }
    if (isEqp) {
      payload.vehicleId = vehicleId;
    }
    if (isState) {
      payload.stateId = stateId;
    }
    if (isStartDate) {
      payload.start = new Date(startDate);
    }
    if (isEndDate) {
      payload.end = new Date(endDate);
    }
    if (isPrefix) {
      payload.prefix = prefix;
    }

    payload.loadMap = true;

    props.onSearch(payload);
  };

  const onCancel = (e) => {
    e.preventDefault();
    let payload = {};

    if (isDriver) {
      payload.driverId = "";
      setDriver("");
    }
    if (isEqp) {
      payload.vehicleId = "";
      setVehicle("");
    }
    if (isState) {
      payload.stateId = "";
      setState("");
    }

    if (isStartDate) {
      payload.start = null;
      // setStartDate(null);
      setStartDate("");
    }
    if (isEndDate) {
      payload.end = null;
      // setEndDate(null);
      setEndDate("");
    }
    if (isTimeperiod) {
      payload.end = null;
      setTimeperiod(null);
    }

    props.onSearch(payload);
  };

  const setDates = (value) => {
    const currTime = moment();

    // const startOfDay = props.formatDateTimeUtc(moment().startOf("day"));
    // const endOfDay = props.formatDateTimeUtc(moment());
    // setStartDate(startOfDay);
    // setEndDate(endOfDay);

    if (value === 1) {
      setStartDate(props.formatDateTimeUtc(moment().startOf("day")));
      setEndDate(props.formatDateTimeUtc(currTime));
    } else if (value === 2) {
      setStartDate(
        props.formatDateTimeUtc(
          props.getStartOfDay(moment().subtract(1, "days"))
        )
      );
      setEndDate(
        props.formatDateTimeUtc(props.getEndOfDay(moment().subtract(1, "days")))
      );
    } else if (value === 3) {
      setStartDate(props.formatDateTimeUtc(moment().startOf("week")));
      setEndDate(props.formatDateTimeUtc(currTime));
    } else if (value === 4) {
      setStartDate(props.formatDateTimeUtc(moment().startOf("month")));
      setEndDate(props.formatDateTimeUtc(currTime));
    }
  };

  const dateOptions = props.dateOptions || [
    { name: LABELS.today, id: 1 },
    { name: LABELS.yesterday, id: 2 },
    { name: LABELS.thisWeek, id: 3 },
    { name: LABELS.thisMonth, id: 4 },
  ];

  const viewOptions = [
    { name: LABEL.map, id: "Map" },
    { name: LABEL.table, id: "Table" },
  ];

  const driversArray =
    Array.isArray(drivers) &&
    drivers.sort((a, b) => {
      return a.firstName
        .trim()
        .toLowerCase()
        .localeCompare(b.firstName.trim().toLowerCase());
    });

  return (
    <div style={{ padding: 15 }}>
      <Grid container spacing={3}>
        {isDriver && (
          <Grid item sm xs={12}>
            <FormControl variant="standard" fullWidth size="small">
              <InputLabel htmlFor="driverId">{LABELS.driver}</InputLabel>
              <Select
                value={driverId}
                onChange={(e) => setDriver(e.target.value)}
                inputProps={{
                  name: "driverId",
                  id: "driverId",
                }}
              >
                <MenuItem value={""}>All Drivers</MenuItem>
                {driversArray.map((item, i) => (
                  <MenuItem
                    key={i}
                    value={item.id}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.firstName} {item.lastName}{" "}
                    {dl ? `(${item.licenseNumber})` : ""}
                    {allDrivers ? `(${item.status})` : ""}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {props.tracking && !driverId && !vehicleId ? (
              <span
                style={{ color: "red", marginLeft: "10px", fontSize: "14px" }}
              >
                {LABEL.required}
              </span>
            ) : null}
          </Grid>
        )}
        {isEqp && (
          <Grid item sm xs={12}>
            <FormControl variant="standard" fullWidth size="small">
              <InputLabel htmlFor="vehicleId">{LABEL.vehicle}</InputLabel>
              <Select
                value={vehicleId}
                onChange={(e) => setVehicle(e.target.value)}
                inputProps={{
                  name: "vehicleId",
                  id: "vehicleId",
                }}
              >
                <MenuItem value={""}>{LABELS.allEquipments}</MenuItem>
                {Array.isArray(equipments) &&
                  equipments.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.name}
                      {isTrailer ? (
                        <small
                          style={{
                            marginLeft: 10,
                          }}
                        >
                          {` (${item.assetType})`}{" "}
                        </small>
                      ) : (
                        ""
                      )}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {isState && (
          <Grid item sm xs={12}>
            <FormControl variant="standard" fullWidth size="small">
              <InputLabel htmlFor="stateId">{LABELS.allStates}</InputLabel>
              <Select
                value={stateId}
                onChange={(e) => setState(e.target.value)}
                inputProps={{
                  name: "stateId",
                  id: "stateId",
                }}
              >
                <MenuItem value={""}>{LABELS.allStates}</MenuItem>
                {Array.isArray(allStates) &&
                  allStates.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.id}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {isTimeperiod && !stateMil && (
          <Grid item sm xs={12} md={1}>
            <FormControl variant="standard" fullWidth size="small">
              <InputLabel htmlFor="timeperiod">{LABELS.time}</InputLabel>
              <Select
                value={timeperiod || ""}
                onChange={(e) => {
                  setTimeperiod(e.target.value);
                  setDates(e.target.value);
                }}
                inputProps={{
                  name: "timeperiod",
                  id: "timeperiod",
                }}
              >
                <MenuItem value={""}>{LABELS.time_period}</MenuItem>
                {Array.isArray(dateOptions) &&
                  dateOptions.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {props.tracking && (
          <Grid item sm xs={12} md={1}>
            <FormControl variant="standard" fullWidth size="small">
              <InputLabel htmlFor="timeperiod">{LABEL._view}</InputLabel>
              <Select
                value={view || ""}
                onChange={(e) => {
                  setView(e.target.value);
                  props.handleView(e.target.value);
                }}
                inputProps={{
                  name: "view",
                  id: "view",
                }}
              >
                {Array.isArray(viewOptions) &&
                  viewOptions.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {stateMil && (
          <Grid item sm xs={12}>
            <FormControl variant="standard" fullWidth size="small">
              <InputLabel htmlFor="quarter">{LABEL.quarter}</InputLabel>
              <Select
                value={iftaYears || ""}
                onChange={(e) => {
                  setIftaYears(e.target.value);
                  const year = e.target.value;

                  if (year) {
                    const _sel = IFTA_YEARS.find((item) => item.id === year);
                    setStartDate(props.formatDateTimeUtc(_sel.start));
                    setEndDate(
                      props.formatDateTimeUtc(
                        isPastDate(_sel.end) ? _sel.end : new Date()
                      )
                    );
                  }
                }}
                inputProps={{
                  name: "quarter",
                  id: "quarter",
                }}
              >
                <MenuItem value={""}>{LABEL.quarter}</MenuItem>
                {Array.isArray(IFTA_YEARS) &&
                  IFTA_YEARS.map((item, i) => (
                    <MenuItem key={i} value={item.id}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {isStartDate && (
          <Grid item sm xs={12} md>
            <FormControl variant="standard" fullWidth size="small">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {isTime ? (
                  <DateTimePicker
                    value={dayjs(startDate)}
                    label={startLabel || LABELS.startDate}
                    onChange={(e) => {
                      setStartDate(e);
                      setTimeperiod("");
                      setIftaYears("");
                    }}
                    slotProps={{
                      textField: { size: "small", variant: "standard" },
                    }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    views={[
                      "year",
                      "month",
                      "day",
                      "hours",
                      "minutes",
                      "seconds",
                    ]}
                  />
                ) : (
                  <DatePicker
                    value={dayjs(startDate)}
                    label={startLabel || LABELS.startDate}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: { size: "small", variant: "standard" },
                    }}
                    onChange={(e) => {
                      setStartDate(e);
                      setTimeperiod("");
                      setIftaYears("");
                    }}
                  />
                )}
              </LocalizationProvider>
            </FormControl>
          </Grid>
        )}
        {isEndDate && (
          <Grid item sm xs={12} md>
            <FormControl variant="standard" fullWidth size="small">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {isTime ? (
                  <DateTimePicker
                    value={dayjs(endDate)}
                    label={startLabel || LABELS.endDate}
                    onChange={(e) => {
                      setEndDate(e);
                      setTimeperiod("");
                      setIftaYears("");
                    }}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: { size: "small", variant: "standard" },
                    }}
                    views={[
                      "year",
                      "month",
                      "day",
                      "hours",
                      "minutes",
                      "seconds",
                    ]}
                  />
                ) : (
                  <DatePicker
                    value={dayjs(endDate)}
                    label={startLabel || LABELS.endDate}
                    onChange={(e) => {
                      setEndDate(e);
                      setTimeperiod("");
                      setIftaYears("");
                    }}
                    slotProps={{
                      textField: { size: "small", variant: "standard" },
                    }}
                  />
                )}
              </LocalizationProvider>
            </FormControl>
          </Grid>
        )}
        {isPrefix && (
          <Grid item sm xs={12} md={"auto"}>
            <FormControl variant="standard" fullWidth size="small">
              <InputLabel htmlFor="prefix">{`${LABEL.prefix}`}</InputLabel>
              <Input
                id="prefix"
                size="small"
                label={LABEL.prefix}
                value={prefix}
                onChange={(e) => setPrefix(e.target.value)}
              />
            </FormControl>
          </Grid>
        )}
        <Grid item sm xs={12} md={isReport ? 2 : "auto"}>
          <div>
            <ButtonGroup
              size="small"
              disableElevation
              variant="contained"
              color="primary"
            >
              <Button
                color="primary"
                variant="contained"
                disabled={
                  !isValid ||
                  props.loading ||
                  (props.tracking && !driverId && !vehicleId)
                }
                onClick={onSearch}
                ref={props.refrence}
              >
                {loading ? <CircularProgress size={20} /> : <SearchIcon />}
              </Button>
              <Button color="error" onClick={onCancel}>
                {loading ? <CircularProgress size={20} /> : <ClearIcon />}
              </Button>

              {isDownload ? (
                <Button 
                disabled={
                 
                  (!driverId && !vehicleId)
                }
                color="primary" onClick={props.onDownload}>
                  {isDownloadIcon ? <DownloadIcon size={20} /> : downloadLabel}
                </Button>
              ) : null}
              {isDetail ? (
                <Button color="primary" onClick={props.onDetail}>
                  <SummarizeIcon size={20} />
                </Button>
              ) : null}
            </ButtonGroup>
          </div>
        </Grid>
      </Grid>
    </div>
  );
  // }
};

const mapStateToProps = (state) => {
  return {
    drivers: _get(state, "driverData.drivers", []),
    equipments: _get(state, "equipmentData.equipments", []),
    accounts: _get(state, "accountData.accounts", []),
    constants: _get(state, "appData.constants", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDrivers,
      getEquipments,
      getAccounts,
      formatDate,
      formatTime,
      formatDateTimeSec,
      formatDateTimeUtc,
      getStartOfDay,
      getEndOfDay,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SearchBar)
);
