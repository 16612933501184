import React from "react";
import _get from "lodash/get";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import { bindActionCreators } from "redux";
import { formatDate, formatDateTime } from "../../actions/momentActions";
import Grid from "@mui/material/Grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import GetAppIcon from "@mui/icons-material/GetApp";
import NotFoundTableRow from "../NotFoundTableRow";
import { usStates } from "../../utils/constants";
import TripDetail from "./TripDetail";
import { getMeterToMi } from "./helpers";
import { LABELS as _LABELS } from "../../language";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";


const LABELS = _LABELS.settingPage;

const STYLES = {
  mainDiv: {
    margin: "0 50px 0",
  },
  table: {
    border: "0.1px solid #ccc",
    width: "98%",
    borderCollapse: "collapse",
    margin: "auto",
    fontSize: "13px",
  },
  td: {
    border: "0.2px solid #ccc",
    // width: "35%",
    textAlign: "center",
    padding: "20px 0 20px 0",
  },
  tStyle: {
    padding: "20px 0 20px 0",
  },
  message: {
    width: "90%",
    margin: "auto",
    marginTop: 20,
  },
  reportHead: {
    textAlign: "center",
    fontSize: "20px",
    marginBottom: "10px",
  },
};

function ReduxTableRow({ item = {}, prefix }) {
  const distance = getMeterToMi(item.distance, prefix);
  const stateObj = usStates.find((it) => item.country === it.name) || {};

  return (
    <tr style={STYLES.td}>
      <td style={STYLES.tStyle}>{stateObj.abbreviation || ""}</td>
      <td className="text-capitalize">{`${distance} Mi(s)`}</td>
    </tr>
  );
}

class stateTable extends React.Component {
  state = {
    limit: 10,
    page: 0,
    loading: false,
    isDetailed: false,
    isEdit: false,
    isTripDetail: false,
    selectedState: "", 
  };

  handleOpen = (data) => {
    this.setState({ isEdit: true, selItem: data });
  };
  handleStateChange = (event) => {
    const selectedState = event.target.value;
    console.log("selectedstate:::___", selectedState);
    const stateObj = usStates.find((state) => state.abbreviation === selectedState);
    // console.log("StateObject___::::",stateObj)
    this.setState({ selectedState: stateObj ? stateObj.name : "" });
  };

  renderCsvData = () => {
    const start = this.props.start;
    const end = this.props.end;
    const prefix = this.props.prefix;
    const stateMiles = _get(this, "props.allRoutes", []);

    if (!stateMiles || !Array.isArray(stateMiles) || stateMiles.length === 0) {
      return null;
    }
    const csvData = stateMiles.map((item) => {
      const distance = getMeterToMi(item.distance, prefix);
      const stateObj = usStates.find((it) => item.country === it.name) || {};
      const data = {
        REPORT: "STATE MILEAGE",
        START_DATE: `${this.props.formatDate(start)}`,
        END_DATE: `${this.props.formatDate(end)}`,
        STATE: `${stateObj.abbreviation || ""}`,
        MILES: `${distance}`,
      };
      return data;
    });

    return (
      <CSVLink
        data={csvData}
        filename={`StateMileage_Report.csv`}
        style={{
          textDecoration: "none",
          color: !stateMiles ? "#F3F3F3" : "#369BE5",
        }}
      >
        CSV
      </CSVLink>
    );
  };

  render() {

    const { isTripDetail, selectedState } = this.state;
    const { isState, } = this.props;
    const prefix = this.props.prefix;
    const { start, end, carrier, download, accountBaseUrl, constants } = this.props;
    const vehicle = _get(this.props, "vehicle[0].name", "");
    const stateMiles = _get(this, "props.allRoutes", []);
    const allStates = constants.US_STATES;
    const filteredStateMiles = selectedState
      ? stateMiles.filter(item => item.country === selectedState)
      : stateMiles;

    const totalKm = filteredStateMiles.reduce((prev, cur) => prev + parseInt(cur.distance), 0);
    const totalMi = getMeterToMi(totalKm, prefix);

    if (stateMiles && stateMiles.length === 0) {
      return null;
    }
    return (
      <>
        {isTripDetail && (
          <TripDetail
            open={isTripDetail}
            allStatus={this.props.allStatus}
            deleteIftaLogs={this.props.deleteIftaLogs}
            handleClose={() => this.setState({ isTripDetail: false })}
          />
        )}
        <Grid container style={{ marginBottom: "15px" }}>
          <Grid item xs={6} md={6} style={{ marginLeft: "5vh" }} />
          <Grid item xs={6} md={5} style={{ textAlign: "right" }}>
            <ButtonGroup variant="outlined" aria-label="Ifta Buttons" >
              <Button
                variant="outlined"
                size="small"
                onClick={() => this.setState({ isTripDetail: true })}
              >
                {LABELS.trips}
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={this.props.downloadReport}
              >
                <GetAppIcon />
                {LABELS.pdf}
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => this.props.emailReport(stateMiles, prefix)}
              >
                <MailOutlineIcon />
                {" Email"}
              </Button>

              <Button variant="outlined" size="small">
                <GetAppIcon />
                {this.renderCsvData()}
              </Button>
              <Button variant="outlined" size="small">
                <Link
                  color="primary"
                  style={{ textDecoration: "none", color: "#009be5" }}
                  to={`${accountBaseUrl}/state-mileage/detail`}
                >
                  {LABELS.details}
                </Link>
              </Button>
              {isState && (
                <FormControl variant="outlined" size="small" style={{ width: "115px", }} 
                sx={{ "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "#8cd1fb", borderRadius: "0 8px 8px 0", }, "&:hover fieldset": { borderColor: "#8cd1fb" }, } }} >
                  <InputLabel style={{ color: "#009be5", fontSize: "14px", }} >{"ALL STATE"}</InputLabel>
                  <Select
                    label="ALL STATE"
                    onChange={this.handleStateChange}
                    value={this.selectedState}
                    disableUnderline
                  >
                    <MenuItem value={""}>{"All State"}</MenuItem>
                    {Array.isArray(allStates) &&
                      allStates.map((item, i) => (
                        <MenuItem key={i} value={item.id}>
                          {item.id}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            </ButtonGroup>
          </Grid>
        </Grid>
        <Grid id="print_log" style={STYLES.mainDiv}>
          <Paper elevation={3} style={{ padding: "10px", minHeight: "550px" }}>
            <Grid item xs={12}>
              <p style={STYLES.reportHead}>{LABELS.state_mileage_report}</p>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <p
                    style={{
                      marginLeft: "30px",
                      marginBottom: "0",
                    }}
                  >
                    {`${LABELS.carrierName}: ${carrier}`}
                  </p>
                  <p style={{ marginLeft: "30px" }}>
                    {`${LABELS.report} : ${this.props.formatDate(start) || this.props.formatDate()
                      }`}
                    {` TO ${this.props.formatDate(end) || this.props.formatDate()
                      }`}{" "}
                  </p>
                </Grid>

                <Grid item xs={12} sm={6} style={{ marginBottom: "20px" }}>
                  <p
                    style={{
                      marginRight: "30px",
                      marginBottom: "0",
                      textAlign: "right",
                    }}
                  >
                    {`${LABELS.vehicleName} : ${
                      vehicle ? vehicle : "All Vehicles"
                    }`}
                  </p>
                  <p
                    style={{
                      marginRight: "30px",
                      textAlign: "right",
                    }}
                  >
                    {`${LABELS.miles} : ${totalMi} ${LABELS.miles}`}
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <table style={STYLES.table}>
              <thead>
                <tr>
                  <th style={STYLES.tStyle} scope="col">
                    {LABELS.state}
                  </th>
                  <th scope="col">{LABELS.mileage_miles}</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(filteredStateMiles) &&
                  filteredStateMiles.map((item, index) => (
                    <ReduxTableRow
                      key={index}
                      item={item}
                      formatDateTime={this.props.formatDateTime}
                      download={download}
                      handleOpen={this.handleOpen}
                      prefix={prefix}
                    />
                  ))}
              </tbody>
            </table>
            <NotFoundTableRow items={filteredStateMiles} />
          </Paper>
        </Grid>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    constants: _get(state, "appData.constants", {}),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      formatDate,
      formatDateTime,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(stateTable);
