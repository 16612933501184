import React, { Component } from "react";
import _get from "lodash/get";
import html2pdf from "html2pdf.js";
// import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { pdfOptions } from "../../helpers/helpers";
import {
  // getDuration,
  utcOffset,
  roundOff,
  dateToday,
} from "../../utils/momentHelpers";
import {
  formatTime,
  getEndOfDay,
  getStartOfDay,
  formatRawDate,
} from "../../actions/momentActions";
import { getProfile } from "../../actions/auth/authActions";
import { getDriverSignById } from "../../actions/driverActions";
import { getLogPostUrl } from "../../actions/documentUpload";
import { postSignImage } from "../../actions/signActions";
import DutyStatus from "../../components/DutyStatus";
import LogGrid from "./LogGrid";
import EmptyLogGrid from "./EmptyLogGrid";
import SignatureTable from "./SignatureTable";
import {
  getTrailerDriven,
  getShippingDocs,
  getEngineHours,
  // getVehicleDriven,
} from "./infoHelper";

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  table: {
    minWidth: 700,
  },
  small: {
    minWidth: 150,
  },
  medium: {
    minWidth: 200,
  },
  large: {
    minWidth: 300,
  },
  speed: {
    display: "inline-block",
    marginLeft: 10,
    fontWeight: 600,
  },
  buttonProgress: {
    color: "green[500]",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const STYLES = {
  mainDiv: {
    width: "100%",
  },
  table: {
    border: "0.1px solid #ccc",
    width: "90%",
    borderCollapse: "collapse",
    margin: "auto",
    fontSize: "13px",
  },
  td: {
    border: "0.2px solid #ccc",
    width: "35%",
    textAlign: "left",
    padding: 5,
  },
  th: {
    border: "0.2px solid #ccc",
  },

  tdLog: {
    border: "0.1px solid #ccc",
    width: "100%",
    textAlign: "center",
    padding: 5,
  },
  td2: {
    border: "0.1px solid #ccc",
    width: "20%",
    textAlign: "center",
    padding: 5,
  },
  tvalue: {
    display: "inline-block",
    margin: 0,
    marginLeft: 15,
  },
  message: {
    width: "90%",
    margin: "auto",
    marginTop: 20,
  },
  spanSt: {
    float: "left",
    minWidth: 170,
  },
  captVal: {
    textTransform: "capitalize",
    display: "inline-block",
    margin: 0,
    marginLeft: 15,
  },
  saveBtn: {
    display: "inline-block",
    marginTop: "20px ",
    width: "10%",
  },
  downloadBtn: {
    display: "inline-block",
    margin: "20px 10px ",
    width: "15%",
  },
  fSize: {
    fontSize: "12px",
    float: "left",
    minWidth: 170,
  },
  pSize: {
    fontSize: "12px",
    display: "inline-block",
    margin: 0,
    marginLeft: 15,
  },
};

class LogCertify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folder: "certify",
      saving: false,
      signOpen: false,
    };
  }

  componentDidMount() {
    this.getDriverSignature();
  }

  getDriverSignature = async () => {
    try {
      const driverId = _get(this, "props.selectedDriver.driver.id");
      const response = await this.props.getDriverSignById({ driverId });
      if (response.sign.data) {
        this.setState({ signature: response.sign.data }, () => {
          if (this.props.isAuto) {
            this.save();
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  uploadLog = async () => {
    const { accountId, bucket, dailyDiary, webview } = this.props;
    const selectedDriver =
      this.state.selectedDriver || this.props.selectedDriver || {};
    const diary = (dailyDiary && dailyDiary[0]) || {};
    const { folder } = this.state;
    const vehicle = _get(selectedDriver, "vehicle.name", "");
    const statusDate = new Date(diary.date)
      .toString()
      .toUpperCase()
      .split(" ")
      .splice(1, 3)
      .join("");
    const fullName = `${selectedDriver.firstName}${selectedDriver.lastName}`;
    const fileName = `CL${statusDate}${fullName}${vehicle}.${"pdf"}`.replace(
      / /g,
      ""
    );

    window.scrollTo(0, 0);
    const element = document.getElementById("print_log");

    pdfOptions.filename = fileName;
    pdfOptions.margin = [0.3, 0.1];
    if (webview) {
      this.setState({ download: true });
      await html2pdf().set(pdfOptions).from(element).toPdf().save();
      this.setState({ download: false });
      if (this.props.isAuto) {
        this.props.handleClose();
      }
    } else {
      this.setState({ saving: true });
      const pdfData = await html2pdf()
        .set(pdfOptions)
        .from(element)
        .toPdf()
        .output("blob");

      this.setState({ fileName });
      const response = await this.props.getLogPostUrl({
        accountId,
        bucket,
        folder,
        name: fileName,
      });

      const uploadUrl = _get(response, "s3.url");
      if (uploadUrl) {
        this.setState({ bucketUrl: uploadUrl });

        try {
          await fetch(uploadUrl, {
            method: "PUT",
            body: pdfData,
          });
          return {
            bucket,
            folder,
            name: fileName,
          };
        } catch (e) {
          this.props.onCertifyDone();
        }
      } else {
        this.props.onCertifyDone();
      }
    }
  };

  save = async (values) => {
    const { webview, dailyDiary, signature } = this.props;

    if (signature && !webview) {
      this.setState({ saving: true, errorMessage: "", successMessage: "" });
      const response = await this.uploadLog();

      this.setState({ saving: false });
      if (response && response.name) {
        // await this.props.certifyDiary(response);
        let diary = (dailyDiary && dailyDiary[0]) || {};
        diary.certified = true;
        diary.media = response;
        diary.certifyTime = new Date();

        const responseDiary = await this.props.updateDriverDaily(diary);
        this.props.onCertifyDone(responseDiary);
      } else {
        this.props.onCertifyDone();
      }
    } else if (webview) {
      await this.uploadLog();
    } else {
      this.setState({ errorMessage: "Signature is required!", imageUrl: "" });
    }
  };

  handleClose = () => {
    this.setState({
      signOpen: false,
    });
  };

  getImage = async (imageUrl) => {
    const { selectedDriver, signature } = this.props;
    const driverId = _get(selectedDriver, "userId", "");
    if (imageUrl) {
      const image = imageUrl && imageUrl.split(",");
      const imageStr = image && image[1];
      const params = {
        userId: driverId,
        data: imageStr,
      };
      await this.props.postSignImage(params);
      await this.props.getProfile();
    }

    if (signature) {
      this.setState({ imageUrl: signature });
    } else {
      this.setState({ imageUrl });
    }
  };

  openSign = () => {
    this.setState({ signOpen: true });
  };

  getVehicleDriven = () => {
    const { driverLogs = [] } = this.props;
    let all = {};
    driverLogs.forEach((item) => {
      const name = item.vehicle && item.vehicle.name;
      // const vin = item.vehicle && item.vehicle.vin;
      if (name && name !== "null") {
        all[name] = `${name}`;
      }
    });
    const array = Object.values(all);

    return array.map((item, idx) => {
      const sep = array.length - 1 === idx ? "" : ",";
      return <span key={`id-${idx}`}>{`${item}${sep} `}</span>;
    });
  };

  getVehicleDrivenVin = () => {
    const { driverLogs = [] } = this.props;
    let all = {};
    driverLogs.forEach((item) => {
      const name = item.vehicle && item.vehicle.vin;
      // const vin = item.vehicle && item.vehicle.vin;
      if (name && name !== "null") {
        all[name] = `${name}`;
      }
    });

    const array = Object.values(all);

    return array.map((item, idx) => {
      const sep = array.length - 1 === idx ? "" : ",";

      return <span key={`id-${idx}`}>{`${item}${sep} `}</span>;
    });
  };

  // getTrailerDriven = () => {
  //     const { driverLogs = [] } = this.props;
  //     let all = {};
  //     driverLogs.forEach(item => {
  //         const name = item.trailer;
  //         if (name && name !== "null") {
  //             all[name] = name;
  //         }
  //     });
  //
  //     return Object.keys(all).join(", ");
  // };

  render() {
    const { saving, imageUrl, errorMessage, signOpen, download } = this.state;
    const {
      dailyDiary,
      selectedDriver,
      // driverLogs,
      timezone,
      allDayEvents,
      dataLoading,
      webview,
      selectedDate,
      fmcsa,
      isUnity,
    } = this.props;
    const signature = this.state.signature;
    // console.log(this.props);

    const logs = _get(this, "props.allDayEvents", []);
    const dDiary = _get(this, "props.dailyDiary[0]", {});
    const data = Array.isArray(logs) ? logs[logs.length - 1] : {};
    const firstLog = _get(this, "props.driverLogs[0]", []);
    const location = _get(data, "location", "");
    const engineHoursLast = _get(data, "engineHours", "");
    const engineHoursFirst = _get(firstLog, "engineHours", "");
    const odometerLast = _get(data, "odometer", "");
    const odometerFirst = _get(firstLog, "odometer", "");
    const driver = _get(selectedDriver, "driver", {});
    const carrier = _get(selectedDriver, "carrier", {});
    const trailer = getTrailerDriven({ driverLogs: logs });
    const shippingDoc = getShippingDocs({ driverLogs: logs });
    const engineHours = getEngineHours({ driverLogs: logs });
    // const ttId = _get(dDiary, "vehicle[0].name", "");
    const coDrName = _get(dDiary, "coDriver[0]", []);
    const coDrId = _get(coDrName, "email", "");
    const coFullName =
      coDrName.length !== 0 ? `${coDrName.firstName} ${coDrName.lastName}` : "";
    const carrierName = _get(carrier, "name", "");
    // const tractorVin = _get(dDiary, "vehicle[0].vin", []);
    const dotNumber = _get(carrier, "dot", "");
    const certified = _get(dDiary, "certified", "");
    const timeZone = _get(this, "props.selectedDriver.carrier.timezone", "");
    const offset = utcOffset(timeZone) / 60;

    let milesToday = odometerLast - odometerFirst;
    // if (logs.length > 1) {
    // milesToday = 0;
    // } else {
    // milesToday = odometerFirst - odometerLast;
    // }

    if (milesToday < 0) {
      milesToday = 0;
    }
    milesToday = Math.round(milesToday);

    return (
      <>
        <div id="print_log" style={STYLES.mainDiv}>
          <div style={{ textAlign: "center" }}>
            {webview ? <h2>LOG BOOK</h2> : <h2>CERTIFIED LOG</h2>}
            <h3>
              {
                "AS REQUIRED BY THE D.O.T FEDERAL MOTOR CARRIER SAFETY REGULATIONS"
              }
            </h3>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <table style={STYLES.table}>
                  <tbody>
                    <tr style={STYLES.td}>
                      <td style={STYLES.td}>
                        <span style={STYLES.spanSt}>{`Record Date :`}</span>
                        <p style={STYLES.tvalue}>{`${this.props.formatRawDate(
                          selectedDate
                        )}`}</p>
                      </td>
                      <td style={STYLES.td}>
                        <span style={STYLES.spanSt}>{`Carrier :`}</span>
                        <p style={STYLES.tvalue}>{`${
                          carrierName && carrierName
                        }`}</p>
                      </td>
                    </tr>

                    <tr style={STYLES.td}>
                      <td style={STYLES.td}>
                        <span style={STYLES.spanSt}>{`Driver Name :`}</span>
                        <p style={STYLES.captVal}>
                          {`${selectedDriver.firstName} ${selectedDriver.lastName} `}
                        </p>
                      </td>
                      <td style={STYLES.td}>
                        <span style={STYLES.spanSt}>{`US DOT Number : `}</span>
                        <p style={STYLES.tvalue}>{`${
                          dotNumber && dotNumber
                        }`}</p>
                      </td>
                    </tr>

                    <tr style={STYLES.td}>
                      <td style={STYLES.td}>
                        <span style={STYLES.spanSt}>{`Driver Id : `}</span>
                        <p style={STYLES.tvalue}>{`${
                          driver.alias && driver.alias
                        }`}</p>
                      </td>
                      <td style={STYLES.td}>
                        <span
                          style={STYLES.spanSt}
                        >{`Truck Tractor Id :`}</span>
                        <p style={STYLES.tvalue}>{this.getVehicleDriven()}</p>
                      </td>
                    </tr>

                    <tr style={STYLES.td}>
                      <td style={STYLES.td}>
                        <span
                          style={STYLES.spanSt}
                        >{`Driver License No : `}</span>
                        <p style={STYLES.captVal}>{`${
                          driver.licenseNumber && driver.licenseNumber
                        } `}</p>
                      </td>
                      <td style={STYLES.td}>
                        <span
                          style={STYLES.fSize}
                        >{`Truck Tractor VIN :`}</span>
                        <p style={STYLES.pSize}>{this.getVehicleDrivenVin()}</p>
                      </td>
                    </tr>

                    <tr style={STYLES.td}>
                      <td style={STYLES.td}>
                        <span
                          style={STYLES.spanSt}
                        >{`Driver License State : `}</span>
                        <p style={STYLES.tvalue}>{`${
                          driver.licenseState && driver.licenseState
                        } `}</p>
                      </td>
                      <td style={STYLES.td}>
                        <span style={STYLES.spanSt}>{`Trailer Id : `}</span>
                        <p style={STYLES.tvalue}>{trailer && trailer}</p>
                      </td>
                    </tr>

                    <tr style={STYLES.td}>
                      <td style={STYLES.td}>
                        <span
                          style={STYLES.spanSt}
                        >{`Exempt Driver Status : `}</span>
                        <p style={STYLES.tvalue}>{`${
                          driver.exemptDriver && driver.exemptDriver
                        }`}</p>
                      </td>
                      <td style={STYLES.td}>
                        <span style={STYLES.spanSt}>{`Co-Driver Name :`}</span>
                        <p style={STYLES.captVal}>{`${
                          coFullName && coFullName
                        } `}</p>
                      </td>
                    </tr>

                    <tr style={STYLES.td}>
                      <td style={STYLES.td}>
                        <span style={STYLES.spanSt}>{`Co-Driver Id : `}</span>
                        <p style={STYLES.tvalue}>{coDrId && coDrId}</p>
                      </td>
                      <td style={STYLES.td}>
                        <span style={STYLES.fSize}>{`Time Zone :`}</span>
                        <p style={STYLES.pSize}>{`${timeZone && timeZone}`}</p>
                      </td>
                    </tr>

                    <tr style={STYLES.td}>
                      <td style={STYLES.td}>
                        <span style={STYLES.spanSt}>{`Miles Today : `}</span>
                        <p style={STYLES.tvalue}>{`${
                          milesToday && milesToday
                        } Mile(s)`}</p>
                      </td>
                      <td style={STYLES.td}>
                        <span
                          style={STYLES.spanSt}
                        >{`Time Zone Offset (UTC) : `}</span>
                        <p style={STYLES.tvalue}>{offset}</p>
                      </td>
                    </tr>

                    <tr style={STYLES.td}>
                      <td style={STYLES.td}>
                        <span style={STYLES.spanSt}>{`Odometer : `}</span>
                        <p style={STYLES.tvalue}>
                          {`${odometerFirst && odometerFirst} - ${
                            odometerLast && odometerLast
                          }`}
                        </p>
                      </td>
                      <td style={STYLES.td}>
                        <span
                          style={STYLES.spanSt}
                        >{`24 period Starting Time :`}</span>
                        <p style={STYLES.tvalue}>{` ${
                          driver.startTime && driver.startTime
                        } `}</p>
                      </td>
                    </tr>

                    <tr style={STYLES.td}>
                      <td style={STYLES.td}>
                        <span style={STYLES.spanSt}>{`Shipping Id :`}</span>
                        <p style={STYLES.tvalue}>{shippingDoc}</p>
                      </td>
                      <td style={STYLES.td}>
                        <span style={STYLES.fSize}>{`Engine Hours :`}</span>
                        <p style={STYLES.tvalue}>
                          {`${engineHoursFirst && engineHoursFirst} - ${
                            engineHoursLast && engineHoursLast
                          }`}
                        </p>
                      </td>
                    </tr>

                    <tr style={STYLES.td}>
                      <td style={STYLES.td}>
                        <span style={STYLES.spanSt}>{`ELD Id :`}</span>
                        <p style={STYLES.tvalue}>{fmcsa.identifier}</p>
                      </td>
                      <td style={STYLES.td}>
                        <span style={STYLES.spanSt}>{`ELD Provider : `}</span>
                        <p style={STYLES.tvalue}>{fmcsa.company}</p>
                      </td>
                    </tr>

                    <tr style={STYLES.td}>
                      <td style={STYLES.td}>
                        <span style={STYLES.fSize}>{`Current Location :`}</span>
                        <p style={STYLES.pSize}>{`${location && location}`}</p>
                      </td>
                      <td style={STYLES.td}>
                        {/*<span style={STYLES.fSize}>{`Current Location :`}</span>
                        <p style={STYLES.pSize}>{`${location && location}`}</p>*/}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              {dataLoading ? (
                <EmptyLogGrid
                  containerId={webview ? "webViewLogChart" : "dutyStatusChart"}
                />
              ) : (
                <LogGrid
                  timezone={timezone}
                  dailyDiary={dailyDiary}
                  driverLogs={allDayEvents}
                  hideResetLines={this.props.hideResetLines}
                  containerId={webview ? "webViewLogChart" : "dutyStatusChart"}
                  formatTime={this.props.formatTime}
                />
              )}
            </Grid>

            <Grid item xs={12} md={12} lg={12} style={{ marginTop: 30 }}>
              <table style={STYLES.table}>
                <tbody>
                  <tr>
                    <th style={STYLES.td2}>Status</th>
                    <th style={STYLES.td2}>Time</th>
                    <th style={STYLES.td2}>Location </th>
                    <th style={STYLES.td2}>Odometer(Mi)</th>
                    {isUnity && <th style={STYLES.td2}>Engine Hours</th>}
                    <th style={STYLES.td2}>Annotation</th>
                  </tr>

                  {Array.isArray(logs) &&
                    logs.map((item, index, elements) => {
                      let time = "";
                      let day = "";
                      // let nextTime = "";
                      // let currTime = "";
                      let total = "";
                      let pdfBreak = "";

                      const evtTime = item.eventTimeOrg || item.eventTime;
                      const eventTime = this.props.formatTime(
                        evtTime,
                        timezone
                      );
                      const eventDate = this.props.formatRawDate(
                        evtTime,
                        timezone
                      );
                      const odometer = item.odometer;
                      const engineHours = item.engineHours;

                      // if (webview && dDiary && dDiary.date === dateToday) {
                      //   if (elements.length === 1) {
                      //     currTime = this.props.getStartOfDay(
                      //       moment(_get(item, "eventTime", ""))
                      //     );
                      //     nextTime = moment();
                      //   } else {
                      //     if (index === elements.length - 1) {
                      //       currTime = moment(_get(item, "eventTime", ""));
                      //       nextTime = moment();
                      //     } else {
                      //       currTime = moment(_get(item, "eventTime", ""));
                      //       nextTime = moment(
                      //         _get(elements[index + 1], "eventTime", "")
                      //       );
                      //     }
                      //   }
                      //
                      //   total = getDuration(currTime, nextTime);
                      // } else if (elements.length === 1) {
                      //   let testTime = moment(_get(item, "eventTime", ""));
                      //   currTime = this.props.getStartOfDay(testTime);
                      //
                      //   nextTime = this.props.getEndOfDay(testTime);
                      //   total = getDuration(currTime, nextTime);
                      // } else if (index === 0) {
                      //   if (_get(item, "eventTime._i", "")) {
                      //     currTime = this.props.getStartOfDay(
                      //       _get(item, "eventTime._i", "")
                      //     );
                      //   } else {
                      //     currTime = this.props.getStartOfDay(
                      //       _get(item, "eventTime", "")
                      //     );
                      //   }
                      //   nextTime = moment(
                      //     _get(elements[index + 1], "eventTime", "")
                      //   );
                      //   total = getDuration(currTime, nextTime);
                      // } else if (index === elements.length - 1) {
                      //   currTime = moment(_get(item, "eventTime", ""));
                      //   nextTime = this.props.getEndOfDay(currTime);
                      //   total = getDuration(currTime, nextTime);
                      // } else {
                      //   currTime = moment(_get(item, "eventTime", ""));
                      //   nextTime = moment(
                      //     _get(elements[index + 1], "eventTime", "")
                      //   );
                      //   total = getDuration(currTime, nextTime);
                      // }

                      if (typeof item.eventTime === "object") {
                        day = this.props.formatRawDate(
                          _get(item, "eventTime._i", "")
                        );
                        // time = "12:00 AM";
                      } else {
                        day = this.props.formatRawDate(
                          _get(item, "eventTime", "")
                        );
                        time = this.props.formatTime(
                          roundOff(_get(item, "eventTime", ""))
                        );
                      }

                      if (
                        elements.length === 1 &&
                        dDiary &&
                        dDiary.date !== dateToday
                      ) {
                        total = "24H00M";
                      }

                      if (index === 2 || (index - 2) % 16 === 0) {
                        pdfBreak = "html2pdf__page-break";
                      } else {
                        pdfBreak = "";
                      }

                      if (!item.eventType || item.isLast) {
                        return null;
                      }

                      if (item.recordOrigin === "UNIDENTIFIED") {
                        return null;
                      }

                      if (
                        item.recordStatus === "INACTIVE_CHANGED" ||
                        item.recordStatus === "INACTIVE_CHANGE_REJECTED"
                      ) {
                        return null;
                      }

                      if (
                        !isUnity &&
                        (item.eventType === "MALFUNCTION_DIAGNOSTIC" ||
                          item.eventType === "POWERUP_SHUTDOWN" ||
                          item.eventType === "CERTIFICATION" ||
                          item.eventType === "INTERMEDIATE_LOG" ||
                          item.eventType === "LOGIN_LOGOUT" ||
                          item.recordStatus === "INACTIVE_DEACTIVATE")
                      ) {
                        return null;
                      }

                      // console.log(item);
                      // console.log(item.eventType);
                      return (
                        <React.Fragment key={`uId${item.cid}_${index + 1}`}>
                          <tr
                            className={pdfBreak}
                            key={`uId${item.cid}_${index + 1}`}
                          ></tr>
                          <tr
                            style={STYLES.tdLog}
                            key={`id_${item.cid}+${index}`}
                          >
                            <td style={STYLES.td2}>
                              <DutyStatus
                                status={item}
                                styles={{ border: 0, color: "black" }}
                                eventType={item.eventType}
                                eventCode={item.eventCode}
                                annotation={item.annotation}
                                eventSubType={item.eventSubType}
                              />
                            </td>
                            <td style={STYLES.td2}>
                              <span style={{ display: "block" }}>
                                {eventDate}
                              </span>
                              <span>{eventTime}</span>
                            </td>
                            <td style={STYLES.td2}>{item.location}</td>
                            <td style={STYLES.td2}>{odometer}</td>
                            {isUnity && (
                              <td style={STYLES.td2}>{engineHours}</td>
                            )}
                            <td style={STYLES.td2}>{item.annotation}</td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                </tbody>
              </table>
            </Grid>

            <div style={{ marginTop: "20px" }}>
              {
                "I hereby certify that my data entries and my record of duty status for this day are true and correct"
              }
            </div>

            <div style={{ marginTop: "10px" }}>
              {dDiary && dDiary.certified && webview && (
                <SignatureTable
                  handleClose={this.handleClose}
                  signOpen={signOpen}
                  saving={saving}
                  getImage={this.getImage}
                  webview={webview}
                  dDiary={dDiary}
                  signature={signature}
                  imageUrl={imageUrl}
                  openSign={this.openSign}
                />
              )}
              {!webview && (
                <SignatureTable
                  handleClose={this.handleClose}
                  signOpen={signOpen}
                  saving={saving}
                  getImage={this.getImage}
                  webview={webview}
                  dDiary={dDiary}
                  signature={signature}
                  imageUrl={imageUrl}
                  openSign={this.openSign}
                />
              )}
            </div>
          </div>
        </div>
        <Grid item xs={12} md={12} lg={12} style={{ textAlign: "center" }}>
          {errorMessage && !imageUrl && !webview && (
            <Alert
              severity="error"
              style={{ color: "red", margin: "10px 33px" }}
            >
              {errorMessage}
            </Alert>
          )}
          {certified && !webview && (
            <Alert
              severity={certified ? "success" : "error"}
              style={STYLES.message}
            >
              {certified
                ? "Log Certified Successfully!"
                : "Something Went Wrong!"}
            </Alert>
          )}

          {!webview && (
            <Button
              variant="outlined"
              color="primary"
              disabled={saving}
              style={STYLES.saveBtn}
              onClick={this.save}
            >
              {saving ? (
                <>
                  <CircularProgress
                    size={16}
                    className={styles.buttonProgress}
                  />{" "}
                  Saving...
                </>
              ) : (
                "SAVE"
              )}
            </Button>
          )}

          {webview && (
            <Button
              variant="outlined"
              color="primary"
              disabled={download}
              style={STYLES.downloadBtn}
              onClick={this.save}
            >
              {download ? (
                <>
                  <CircularProgress
                    size={14}
                    className={styles.buttonProgress}
                  />{" "}
                  Downloading
                </>
              ) : (
                "DOWNLOAD"
              )}
            </Button>
          )}
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  // let signature = _get(state, "authData.profile.driver.signature.data");

  return {
    logs: _get(state, "logData.logs", []),
    fmcsa: _get(state, "appData.appConfig.fmcsa", []),
    driverRealTime: _get(state, "driverData.driverRealTime", []),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
    bucket: _get(state, "authData.profile.accountProperties.bucket", ""),
    signature: _get(state, "authData.profile.driver.signature.data"),
    isUnity: _get(state, "appData.isUnity", false),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getLogPostUrl,
      formatTime,
      getEndOfDay,
      getStartOfDay,
      formatRawDate,
      postSignImage,
      getProfile,
      getDriverSignById,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LogCertify))
);
