import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { reduxForm } from "redux-form";
import { getCarriers } from "../../../actions/carrierActions";
import { getDvirTemplates } from "../../../actions/dvirActions";
import {
  createEquipment,
  updateEquipment,
} from "../../../actions/equipmentActions";
import { getDashCameras } from "../../../actions/dashcam/dashcamActions";
import validate from "./validate";
import Grid from '@mui/material/Grid';
import ReduxButton from "../../../components/ReduxButton";
// import ReduxRadio from "../../../components/ReduxRadio";
import ReduxInput from "../../../components/ReduxInput";
import ReduxSelect from "../../../components/ReduxSelect";
import ReduxCheckBox from "../../../components/ReduxCheckBox";
import { getSelectYears } from "../../../utils/momentHelpers";
import Error from "../../Error";
import Success from "../../Success";
import {LABELS as _LABELS} from "../../../language";

const LABELS=_LABELS.settingPage;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const YEARS = getSelectYears(20);

export class AddEquipmentForm extends React.Component {
  state = {
    dcs: [],
  };

  componentDidMount() {
    this.props.getCarriers();
    this.props.getDvirTemplates();
    this.getDashCameras();
    this.setState({ errorMessage: "", successMessage: "" });
  }

  getDashCameras = async () => {
    try {
      const dcs = await this.props.getDashCameras();
      this.setState({ dcs });
    } catch (e) {}
  };

  save = async (values) => {

    console.log('values ==== ', values);

    if (values.name) {
      values.name = values.name.replace(/^\s+/g, '');
  }
    this.setState({ errorMessage: "", successMessage: "" });
    let response;
    if (this.props.isEdit) {
      response = await this.props.updateEquipment(values);
    } else {
      if (values.assetType === "TRAILER" && !values.vin) {
        values.vin = values.name;
      }
      response = await this.props.createEquipment(values);
    }

    if (response.id) {
      this.setState({
        successMessage: LABELS.successMessage_Equipment,
      });
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response.message) {
      this.setState({ errorMessage: response.message });
    } else {
      if (typeof response !== String) {
        response =LABELS.response;
      }
      this.setState({ errorMessage: response });
    }
  };

  render() {
    const {
      classes,
      loading,
      carriers,
      handleSubmit,
      constants,
      equipmentValues,
      // accountProperties,
      isEdit,
      dvirTemplates,
      adminKey,
    } = this.props;

    const { errorMessage, successMessage } = this.state;

    const isAdmin = this.props.isAdmin;
    const isSmallScreen = window.innerWidth < 400;
    const isVehicle = equipmentValues.assetType === "VEHICLE";

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"lg"}
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {/* <Typography>{isEdit ? "EDIT EQUIPMENT" : "ADD EQUIPMENT"}</Typography> */}
          <Typography>{isEdit ? LABELS.editEquipment : LABELS.addEquipment}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            <Grid container spacing={0}>
              {isEdit ? (
                <Alert
                  severity="warning"
                  style={{
                    width: "100%",
                  }}
                >
                  <strong>
                    {/* IFTA is only available for active trucks. */}
                    {LABELS.ifta_info_note}
                    <br />
                    {/* If you want to disable or delete truck please download state
                    mileage before disable action. */}
                    {LABELS.ifta_info}
                  </strong>
                </Alert>
              ) : (
                <Grid item xs={12} sm={12} md={12}>
                  <Alert
                    severity="warning"
                    style={{
                      width: "100%",
                    }}
                  >
                    <strong>
                      {/* NOTE: Once you add new vehicle, your card on file will be
                      automatically charged. */}
                      {LABELS.noteinfo}
                    </strong>
                  </Alert>
                </Grid>
              )}

              {/* <Grid item xs={12} sm={12} md={12}>
                                <ReduxRadio
                                    name="assetType"
                                    items={constants.ASSET_TYPE}
                                />
                            </Grid> */}

              <Grid item xs={12} sm={12} md={3}>
                <ReduxInput
                  name="name"
                  label={LABELS.name}
                  disabled={isEdit ? !isAdmin : false}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <ReduxInput
                  uppercase={true}
                  name="vin"
                  label={LABELS.vin}
                  disabled={isEdit ? !isAdmin : false}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <ReduxInput name="vehicle.make" label={LABELS.make} />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <ReduxInput name="vehicle.model" label={LABELS.model}/>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <ReduxSelect name="vehicle.year" label={LABELS.year} items={YEARS} />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ReduxSelect
                  name="carrierId"
                  keyName={"id"}
                  valueName={"name"}
                  label={LABELS.carrier}
                  items={carriers}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <ReduxSelect
                  name="regulationMode"
                  label={LABELS.regulationMode}
                  items={constants.REGULATION}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ReduxSelect
                  name="license.country"
                  label={LABELS.country}
                  items={constants.COUNTRIES}
                />
              </Grid>
              {equipmentValues.country === "CA" ? (
                <Grid item xs={12} sm={12} md={2}>
                  <ReduxSelect
                    name="license.state"
                    label={LABELS.state}
                    items={constants.CA_STATES}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={12} md={2}>
                  <ReduxSelect
                    name="license.state"
                    label={LABELS.state}
                    items={constants.US_STATES}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={2}>
                <ReduxInput name="license.plate" label={LABELS.licPlate} />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <ReduxSelect
                  name="dvir"
                  keyName={"name"}
                  valueName={"name"}
                  label={LABELS.dvirForm}
                  items={dvirTemplates}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <ReduxSelect
                  name="vehicle.mode"
                  label={LABELS.type}
                  items={constants.EQUIPMENT_TYPE}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <ReduxSelect
                  name="vehicle.fuel"
                  label={LABELS.fuel}
                  items={constants.EQUIPMENT_FUEL}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ReduxInput name="vehicle.tank" label={'Fuel Tank (Capacity)'} />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ReduxInput name="vehicle.tank2" label={'Fuel Tank 2 (Capacity)'} />
              </Grid>

              {isAdmin && (
                <Grid item xs={12} sm={12} md={2}>
                  <ReduxInput
                    type="number"
                    name="offset"
                    label={LABELS.odometer_offset}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={2}>
                <ReduxInput name="engine.odo" label={'Current Odometer'} />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <ReduxInput name="engine.enghr" label={'Current Engine Hr'} />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ReduxInput name="engine.tank" label={'Fuel Tank (Level)'} />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ReduxInput name="engine.tank2" label={'Fuel Tank 2 (Level)'} />
              </Grid>

              {isVehicle && (
                <React.Fragment>
                  <Grid item xs={12} sm={12} md={3}>
                    <ReduxSelect
                      name="eldDevice.deviceType"
                      label={LABELS.devicetype}
                      items={constants.DEVICE_TYPES}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <ReduxInput name="eldDevice.name" label={LABELS.devicename} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <ReduxSelect
                      name="gpsSource"
                      label={LABELS.gps_source}
                      items={constants.GPS_SOURCE}
                    />
                  </Grid>
                  {isAdmin && (
                    <Grid item xs={12} sm={12} md={3}>
                      <ReduxInput
                        name="eldDevice.macAddress"
                        label={LABELS.macAddrerss}
                      />
                    </Grid>
                  )}
                  {isAdmin && (
                    <Grid item xs={12} sm={12} md={3}>
                      <ReduxInput name="eldDevice.serial" label={LABELS.eld_serial}/>
                    </Grid>
                  )}
                  {isAdmin && (
                    <Grid item xs={12} sm={12} md={3}>
                      <ReduxInput
                        name="eldDevice.firmware"
                        label={LABELS.eld_firmware}
                      />
                    </Grid>
                  )}
                  {isAdmin && (
                    <Grid item xs={12} sm={12} md={3}>
                      <ReduxInput
                        name="eldDevice.bleVersion"
                        label={LABELS.eld_BleVersion}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={3}>
                    <ReduxSelect
                      name="eldDevice.connectionType"
                      label={LABELS.connectionType}
                      items={constants.CONNECTTION_TYPE}
                    />
                  </Grid>

                  {this.props.dcCustomerId && this.state.dcs && (
                    <Grid item xs={12} sm={12} md={3}>
                      <ReduxSelect
                        name="dcId"
                        label={LABELS.dashcam}
                        items={this.state.dcs}
                        keyName="RecorderID"
                        valueName="VehicleID"
                        valueTwoName="SerialNo"
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={3}>
                    <ReduxCheckBox name="active" label={LABELS.active} />
                  </Grid>
                  {this.props.isAdmin && (
                    <Grid item xs={12} sm={12} md={2}>
                      <ReduxCheckBox name="settings.codes" label={LABELS.codes} />
                    </Grid>
                  )}
                  {this.props.isAdmin && (
                    <Grid item xs={12} sm={12} md={2}>
                      <ReduxCheckBox name="settings.ifta" label={LABELS.ifta} />
                    </Grid>
                  )}
                  {adminKey && (
                    <Grid item xs={12} sm={12} md={5}>
                      <ReduxCheckBox
                        name="vbusConnection"
                        label={LABELS.vbusConnection}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              )}

              <Error top={20} message={errorMessage} />
              <Success top={20} message={successMessage} />
            </Grid>
            <DialogActions className={classes.DialogActions}>
              <ReduxButton
                width
                loading={loading}
                onClick={handleSubmit(this.save)}
                marginTop={25}
                label={LABELS.save}
              />
              <Button onClick={this.props.handleClose} color="primary">
                {LABELS.cancel}
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  const adminKey = _get(state, "authData.profile.adminId");
  return {
    constants: _get(state, "appData.constants", {}),
    accountProperties: _get(state, "authData.profile.accountProperties", {}),
    loading: _get(state, "equipmentData.equipmentBusy", false),
    equipmentValues: _get(state, "form.equipmentForm.values", {}),
    carriers: _get(state, "carrierData.carriers", []),
    dvirTemplates: _get(state, "dvirData.dvirTemplates", []),
    errorMessage: _get(state, "authData.errorMessage", ""),
    isAdmin: !!adminKey,
    dcCustomerId: _get(state, "authData.profile.accountProperties.dcId"),
  };
};

AddEquipmentForm = reduxForm({
  form: "equipmentForm",
  validate,
})(AddEquipmentForm);

AddEquipmentForm = connect((state) => {
  const values = _get(state, "equipmentData.selectedEquipment", {});
  return {
    initialValues: {
      vehicle: {
        year: 2023,
        fuel: "DIESEL",
        mode: "HEAVY_DUTY_VEHICLE",
      },
      engine: {},
      ...values,
    },
  };
})(AddEquipmentForm);

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCarriers,
      createEquipment,
      updateEquipment,
      getDvirTemplates,
      getDashCameras,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddEquipmentForm))
);
