import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import queryString from "query-string";
import * as cookie from "react-cookie";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Formik, Form } from "formik";
import InputBox from "../../components/InputBox";
import SelectLangBox from "../../components/SelectLangBox";
import {
  signIn,
  getProfile,
  signOut,
  pushTokenUpdate,
} from "../../actions/auth/authActions";
import validate from "./validate";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Error from "../Error";
import Warning from "../Warning";
import TermsDialog from "./TermsDialog";
import ForgetDialog from "./ForgetDialog";
import UpdatePasswordDialog from "./UpdatePasswordDialog";
import Typography from "@mui/material/Typography";
import { LOGO_URL, LOGIN_ADS, APP_STYLE_ID } from "../../config/index";
import { getToken } from "../../firebase";
import { isSupported } from "firebase/messaging";
import AdsDetail from "./AdsDetail/AdsDetail";
import { LABELS as _LABELS, selectedLang } from "../../language";
const cookies = new cookie.Cookies();

let token;
let userId;
try {
  const values = queryString.parse(window.location.search);
  token = values.token;
  userId = values.id;
} catch (e) {}

const LABELS = _LABELS.logInPage;

export class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      token,
      userId,
      timeOutId: null,
      isTermsDialog: false,
      signInError: "",
      firebaseToken: null,
      loading: false,
    };
    if (token) {
      cookies.set("token", token, {
        path: "/",
        maxAge: 43200,
      });
    }
  }

  componentDidMount() {}

  componentWillUnmount() {}

  updateFirebaseToken = async () => {
    try {
      if (isSupported()) {
        const fcmWebToken = await getToken();
        if (fcmWebToken) {
          // const response =
          await this.props.pushTokenUpdate({
            fcmWebToken,
            platform: "WEB",
          });
        }
      } else {
        console.log("fMessaging :: ", "not supported");
      }
    } catch (e) {
      console.log("setFirebaseToken :: ", e);
    }
  };

  logIn = async (values) => {
    const SIGN_IN_FORM = LABELS.SIGN_IN_FORM;
    values.selApp = this.props.selApp;
    this.setState({ signInError: "", loading: true });
    const response = await this.props.signIn(values);
    const isToken = response && response.token;
    const isTerms = response && response.user && !response.user.terms;

    // console.log("response = ", response);
    if (response && response.code === "TWO_FACTOR_CODE") {
      this.setState({
        loading: false,
        isCode: true,
        signInWarn: "Code is required.",
      });
      return;
    }

    if (isToken && isTerms) {
      return this.renderDialog();
    } else if (isToken) {
      await this.updateFirebaseToken();
      setTimeout(() => this.sucessLogin(), 1000);
    } else {
      this.setState({
        signInError: SIGN_IN_FORM.invalidEmailPwd,
        loading: false,
      });
    }
  };

  sucessLogin = () => {
    const {
      accountBaseUrl,
      isAdmin,
      isPartner,
      isManager,
      isDriver,
      driverId,
    } = this.props;

    if (isManager && accountBaseUrl) {
      this.props.history.push(`${accountBaseUrl}/status/view`);
    } else if (isPartner && accountBaseUrl) {
      this.props.history.push(`/dashboard/accounts`);
    } else if (isAdmin && accountBaseUrl) {
      this.props.history.push(`/dashboard/accounts`);
    } else if (isDriver && accountBaseUrl) {
      this.props.history.push(`${accountBaseUrl}/status/diary/${driverId}`);
    } else {
      this.setState({
        signInError: "Invalid email or password",
        loading: false,
      });
    }
  };

  renderDialog = () => {
    this.setState({ isTermsDialog: true });
  };

  closeTermsDialog = () => {
    this.setState({ isTermsDialog: false, loading: false });
  };

  declineTerms = () => {
    this.closeTermsDialog();
    this.props.signOut();
  };

  render() {
    const {
      isTermsDialog,
      isForgetDialog,
      signInError,
      signInWarn,
    } = this.state;
    const { token, userId, loading, isCode } = this.state;
    const appVersion = this.props.appVersion;

    let isAds = LOGIN_ADS;

    const FORM_LABELS = LABELS.SIGN_IN_FORM;

    return (
      <React.Fragment>
        {isTermsDialog && (
          <TermsDialog
            open={isTermsDialog}
            onSuccess={this.sucessLogin}
            decline={this.declineTerms}
            handleClose={this.closeTermsDialog}
          />
        )}
        {isForgetDialog && (
          <ForgetDialog
            open={isForgetDialog}
            onClose={() => this.setState({ isForgetDialog: false })}
          />
        )}
        {token && userId && (
          <UpdatePasswordDialog
            token={token}
            userId={userId}
            open={!!(token && userId)}
            onClose={() => this.setState({ token: null, userId: null })}
          />
        )}
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          alignContent={"center"}
          id={APP_STYLE_ID}
        >
          {isAds ? (
            <Grid item xs={12} sm={4} md={8}>
              <AdsDetail LABELS={LABELS} />
            </Grid>
          ) : (
            <Grid item xs={12} sm={4} md={4}></Grid>
          )}
          <Grid item xs={12} sm={4} md={4}>
            <Paper
              elevation={isAds ? 20 : 0}
              style={{
                marginTop: isAds ? 0 : "10vh",
                height: isAds ? "100vh" : 475,
                paddingTop: isAds ? "20vh" : "2vh",
                border: isAds ? 0 : "1px solid grey",
              }}
            >
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  language: selectedLang,
                }}
                validate={validate}
                onSubmit={this.logIn}
                enableReinitialize={true}
              >
                {({ values, handleSubmit, setFieldValue }) => {
                  return (
                    <Form style={{ maxWidth: 350, margin: "auto" }}>
                      <Grid container spacing={3}>
                        <Grid
                          item
                          md={12}
                          xs={12}
                          sm={12}
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <img
                            src={LOGO_URL}
                            alt={"Logo"}
                            style={{
                              maxWidth: 150,
                              marginLeft: "auto",
                              textAlign: "center",
                            }}
                          />
                        </Grid>
                        <InputBox
                          md={12}
                          name="email"
                          label={FORM_LABELS.email}
                        />
                        <InputBox
                          md={12}
                          type="password"
                          name="password"
                          label={FORM_LABELS.password}
                        />
                        {isCode && (
                          <InputBox
                            md={12}
                            type="pin"
                            name="pin"
                            label={"Code"}
                          />
                        )}
                      </Grid>
                      {signInError && <Error message={signInError} />}
                      {signInWarn && <Warning message={signInWarn} />}

                      <Grid
                        item
                        md={12}
                        xs={12}
                        style={{
                          marginTop: 45,
                          textAlign: "center",
                        }}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={loading}
                          style={{
                            minWidth: 250,
                          }}
                        >
                          {loading
                            ? FORM_LABELS.logInLoading
                            : FORM_LABELS.logInBtn}
                        </Button>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        style={{
                          marginTop: 15,
                          textAlign: "center",
                        }}
                      >
                        <Button
                          style={{
                            color: "#009be5",
                          }}
                          onClick={() =>
                            this.setState({
                              isForgetDialog: true,
                            })
                          }
                        >
                          {FORM_LABELS.forgetPwd}
                        </Button>
                      </Grid>
                      <SelectLangBox />

                      <Grid style={{ textAlign: "center" }}>
                        <Typography
                          className=""
                          color="textSecondary"
                          gutterBottom
                        >
                          <small>{appVersion}</small>
                        </Typography>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Paper>
          </Grid>
          {!isAds && <Grid item xs={12} sm={4} md={4}></Grid>}
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state,
    selApp: _get(state, "appData.selApp", ""),
    appVersion: _get(state, "appData.appVersion", ""),
    signInError: _get(state, "authData.signInError", ""),
    isDriver: _get(state, "authData.isDriver", false),
    isAdmin: _get(state, "authData.isAdmin", false),
    isPartner: _get(state, "authData.isPartner", false),
    isManager: _get(state, "authData.isManager", false),
    driverId: _get(state, "authData.driverId", ""),
    loading: _get(state, "appData.appBusy", false),
    logInValues: _get(state, "form.logInForm.values", {}),
    allErrors: _get(state, "form.logInForm.syncErrors", {}),
    allFields: _get(state, "form.logInForm.fields", {}),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signIn,
      getProfile,
      signOut,
      pushTokenUpdate,
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogIn));
