import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import {
  selectedAccount,
  createAccount,
  updateAccount,
  getAccounts,
  getAccountsCount,
  getAccountDetails,
} from "../../actions/account/accountAdminActions";
import { formatDate } from "../../actions/momentActions";
// import { getDueInvoicesAcc } from "../../actions/checkout/coActions";
import { getResellers } from "../../actions/reseller/resellerActions";
import AccountTable from "./AccountTable";
import AddAccountForm from "./Forms/AddAccountForm";
import EditAccount from "./Forms/EditAccount";
// import Button from "@mui/material/Button";
import SearchBar from "./SearchBar";
// import CheckoutModal from "../Checkout/CheckoutModal";
import { LABELS as _LABELS } from "../../language";
const LABELS = _LABELS.adminPage;

const styles = (theme) => ({
  addWrapper: {
    padding: 10,
  },
  searchText: {
    display: "inline-block",
    marginTop: 10,
    marginLeft: 5,
  },
});

export class Carrier extends React.Component {
  state = {
    open: false,
    carrier: null,
    selected: null,
    limit: 25,
    page: 0,
    accountCount: 0,
    accounts: [],
    resellers: [],
    accountOrg: [],
    accInvoives: [],
    isAccInvoives: false,
  };

  async componentDidMount() {
    await this.getAccountsCount();
    await this.getAccounts();
    await this.getResellers();
  }

  // getDueInvoicesAcc = async () => {
  //   const response = await this.props.getDueInvoicesAcc({});
  //   if (response && Array.isArray(response) && response.length > 0) {
  //     this.setState({ accInvoives: response, isAccInvoives: true });
  //   }
  // };

  getAccountsCount = async () => {
    const response = await this.props.getAccountsCount({});
    if (response && response.count) {
      this.setState({ accountCount: response.count || 0 });
    }
  };

  getAccounts = async (query) => {
    const { limit, page } = this.state;
    const skip = parseInt(page) * parseInt(limit);
    const accountOrg = await this.props.getAccounts({
      limit,
      skip,
      query,
    });
    this.setState({ accounts: accountOrg, accountOrg, loading: false });
  };

  getResellers = async () => {
    if (this.props.isSuperAdmin) {
      const resellers = await this.props.getResellers({});
      if (resellers && Array.isArray(resellers)) {
        this.setState({ resellers });
      }
    }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false, selected: null });
  };

  handleEdit = (selected) => {
    this.setState({ isEdit: true, selected });
  };

  handleEditClose = () => {
    this.setState({ isEdit: false, selected: null });
    this.getAccounts();
  };
  handleChange = ({ limit, page }) => {
    this.setState({ limit, page }, this.getAccounts);
  };

  timeOut = null;

  onSearchChange = (event) => {
    const query = event.target.value;
    if (this.timeOut) {
      clearTimeout(this.timeOut);
    }
    this.timeOut = setTimeout(() => {
      this.getAccounts(query);
      this.timeOut = null;
    }, 1000);
  };

  handleAccountDetails = (account) => {
    this.props.history.push(`/dashboard/accounts/${account._id}`);
  };

  render() {
    const { classes, isSuperAdmin } = this.props;
    const accounts = this.state.accounts || [];
    const resellers = this.state.resellers || [];
    const { open, selected, isEdit, loading } = this.state;
    // const { isAccInvoives, accInvoives } = this.state;
    return (
      <div>
        {/*isAccInvoives && (
          <CheckoutModal
            invoices={accInvoives}
            open={isAccInvoives}
            handleClose={() => this.setState({ isAccInvoives: false })}
            formatDate={this.props.formatDate}
          />
        )*/}
        {open && (
          <AddAccountForm
            open={open}
            createAccount={this.props.createAccount}
            getAccounts={this.props.getAccounts}
            handleClose={this.handleClose}
            isSuperAdmin={isSuperAdmin}
            resellers={resellers}
          />
        )}
        {isEdit && (
          <EditAccount
            open={isEdit}
            selected={selected}
            updateAccount={this.props.updateAccount}
            getAccounts={this.props.getAccounts}
            handleClose={this.handleEditClose}
            isSuperAdmin={isSuperAdmin}
            resellers={resellers}
          />
        )}
        <Grid container className={classes.addWrapper}>
          <Grid item xs={12} sm={12} md={2}>
            <span className={classes.searchText}>
              {LABELS.count} : {accounts && accounts.length}
            </span>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <SearchBar
              onChange={this.onSearchChange}
              handleOpen={this.handleOpen}
            />
          </Grid>
        </Grid>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <AccountTable
              items={accounts}
              count={this.state.accountCount}
              handleEdit={this.handleEdit}
              formatDate={this.props.formatDate}
              handleChange={this.handleChange}
              limit={this.state.limit}
              page={this.state.page}
              selectedAccount={this.props.selectedAccount}
              getAccountDetails={this.props.getAccountDetails}
              handleAccountDetails={this.handleAccountDetails}
              isStripe={this.props.isStripe}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const userRoles = _get(state, "authData.profile.roles", []);
  let isStripe = _get(state, "appData.appConfig.isStripe", "OFF") === "ON";

  if (userRoles.includes("PARTNER")) {
    isStripe = false;
  }
  return {
    carrierBusy: _get(state, "carrierData.carrierBusy", false),
    accounts: _get(state, "accountData.accounts", []),
    isSuperAdmin: _get(state, "authData.profile.user.adminAccess", false),
    isStripe,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createAccount,
      updateAccount,
      selectedAccount,
      getAccounts,
      getAccountsCount,
      getAccountDetails,
      formatDate,
      getResellers,
      // getDueInvoicesAcc,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Carrier)
);
