import {
  isSmartelds,
  isUnity,
  isEldBooks,
  isBluehorse,
  isVulcansols,
} from "../configureStore";

import { LOGO_SM, LOGO_UNITY, LOGO_EB, SM_LOGO_BH, LOGO_VS } from "../utils/logos";

let htmlHeadDefault = {
  favicon: "/favicon-reseller.ico",
  title: "ELD",
  description: "FMCSA Registered ELD Solution",
  author: "ELD",
  keyword: "ELD, FMCSA, IFTA, HOS, Tracking",
};

let htmlHeadSe = {
  favicon: "/favicon.ico",
  title: "Smartelds",
  description: "FMCSA Registered ELD Solution",
  author: "Asritsolutions LLC",
  keyword: "ELD, Smartelds, FMCSA, IFTA, HOS, Tracking",
};

let htmlHeadUnity = {
  favicon: "/favicon-unity.ico",
  title: "Motioneld",
  description: "FMCSA Registered ELD Solution",
  author: "Unity Eld LLC",
  keyword: "ELD, Motion, Unity, FMCSA, IFTA, HOS, Tracking",
};

let htmlHeadEb = {
  favicon: "/favicon-eldbooks.ico",
  title: "Eldbooks",
  description: "FMCSA Registered ELD Solution",
  author: "Unity Eld LLC",
  keyword: "ELD, FMCSA, IFTA, HOS, Tracking",
};

let htmlHeadBh = {
  favicon: "/favicon-bluehorse.ico",
  title: "Bluehorse",
  description: "FMCSA Registered ELD Solution",
  author: "Blue horse ELD Inc",
  keyword: "ELD, FMCSA, IFTA, HOS, Tracking",
};

let htmlHeadVs = {
  favicon: "/favicon-vulcansols.ico",
  title: "Vulcansols",
  description: "FMCSA Registered ELD Solution",
  author: "Blue horse ELD Inc",
  keyword: "ELD, FMCSA, IFTA, HOS, Tracking",
};

const smarteldsAdminConfig = {
  payments: true,
  reseller: true,
  settings: true,
};
const unityAdminConfig = {
  payments: false,
  reseller: false,
  settings: true,
};
const eldBooksAdminConfig = {
  payments: true,
  reseller: false,
  settings: true,
};
const bluehorseAdminConfig = {
  payments: false,
  reseller: false,
  settings: true,
};
const vulcansolsAdminConfig = {
  payments: false,
  reseller: false,
  settings: true,
};

const smarteldsNavConfig = {
  settings: true,
  reseller: true,
};
const unityNavConfig = {
  settings: false,
  reseller: false,
};
const eldBooksNavConfig = {
  settings: false,
  reseller: false,
};
const bluehorseNavConfig = {
  settings: false,
  reseller: false,
};
const vulcansolsNavConfig = {
  settings: false,
  reseller: false,
};

export const APP_STYLE_ID = isSmartelds
  ? "app-smartelds"
  : isUnity
  ? "app-unity"
  : isEldBooks
  ? "app-eldbooks"
  : isBluehorse
  ? "app-bluehorse"
  : isVulcansols
  ? "app-vulcansols"
  : "";
export const HTML_HEAD = isSmartelds
  ? htmlHeadSe
  : isUnity
  ? htmlHeadUnity
  : isEldBooks
  ? htmlHeadEb
  : isBluehorse
  ? htmlHeadBh
  : isVulcansols
  ? htmlHeadVs
  : htmlHeadDefault;

export const LOGO_URL = isSmartelds
  ? LOGO_SM
  : isUnity
  ? LOGO_UNITY
  : isEldBooks
  ? LOGO_EB
  : isBluehorse
  ? SM_LOGO_BH
  : isVulcansols
  ? LOGO_VS
  : "";

export const LOGIN_ADS = isSmartelds
  ? true
  : isUnity
  ? false
  : isEldBooks
  ? false
  : isBluehorse
  ? false
  : isVulcansols
  ? false
  : false;

export const ADMIN_CONFIG = isSmartelds
  ? smarteldsAdminConfig
  : isUnity
  ? unityAdminConfig
  : isEldBooks
  ? eldBooksAdminConfig
  : isBluehorse
  ? bluehorseAdminConfig
  : isVulcansols
  ? vulcansolsAdminConfig
  : smarteldsAdminConfig;

  export const NAV_CONFIG = isSmartelds
    ? smarteldsNavConfig
    : isUnity
    ? unityNavConfig
    : isEldBooks
    ? eldBooksNavConfig
    : isBluehorse
    ? bluehorseNavConfig
    : isVulcansols
    ? vulcansolsNavConfig
    : smarteldsNavConfig;
