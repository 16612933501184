import * as React from "react";
import _get from "lodash/get";
import { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

export default function ChatInput(props) {
  // console.log('handleChatReply');
  const [loading, setLoaing] = useState(false);
  const [message, setMessage] = useState("");

  async function onSendClick() {
    setLoaing(true);
    try {
      const id = _get(props, "chat.chatId");
      await props.handleChatReply({ id, message });
      setMessage("");
    } catch (e) {
      console.log("e ::: ", e);
    }
    setLoaing(false);
  }

  return (
    <div>
      {loading && (
        <Box sx={{ width: "100%", marginTop: 5 }}>
          <LinearProgress />
        </Box>
      )}
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <IconButton sx={{ p: "10px" }} aria-label="menu" disabled={true}>
          <AttachFileIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Enter message"
          inputProps={{ "aria-label": "search google maps" }}
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          onKeyPress={(e) => {
            if (message && e.key === "Enter") {
              e.preventDefault();
              onSendClick();
            }
          }}
        />
        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
        <IconButton
          disabled={!message}
          color="primary"
          sx={{ p: "10px" }}
          aria-label="Send"
          onClick={onSendClick}
        >
          {loading ? <CircularProgress /> : <SendIcon />}
        </IconButton>
      </Paper>
    </div>
  );
}
