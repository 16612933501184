import {
  isSmartelds,
  isUnity,
  isEldBooks,
  isBluehorse,
  isVulcansols
} from "../../configureStore";
import {
  BLOCK_SCOPES_SM_ADMIN,
  BLOCK_SCOPES_EB_ADMIN,
  BLOCK_SCOPES_BH_ADMIN,
  BLOCK_SCOPES_MO_ADMIN,
  BLOCK_SCOPES_VS_ADMIN
} from "../../constants";

export function getAllowedScopes(props) {
  return isSmartelds
    ? BLOCK_SCOPES_SM_ADMIN
    : isBluehorse
    ? BLOCK_SCOPES_BH_ADMIN
    : isEldBooks
    ? BLOCK_SCOPES_EB_ADMIN
    : isUnity
    ? BLOCK_SCOPES_MO_ADMIN
    : isVulcansols
    ? BLOCK_SCOPES_VS_ADMIN
    : [];
}

export function getScopes(props) {
  const selected = props.selected;
  const _scopes = props.scopes || [];
  const isDriver = selected.role === "DRIVER";
  const scopes = _scopes.filter((item) => {
    return isDriver ? item.isDriver : item.isManager;
  });

  let all = {};

  for (let i = 0; i < scopes.length; i++) {
    const item = scopes[i];
    const name = item.name;
    const keyName = name.split("_")[1];
    all[keyName] = all[keyName] || {};
    all[keyName].items = all[keyName].items || [];
    all[keyName].items.push(item);
  }

  return Object.keys(all).map((item) => {
    return {
      name: item,
      data: all[item].items,
    };
  });
}
