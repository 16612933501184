import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import ImageIcon from "@mui/icons-material/Image";
import TextField from "@mui/material/TextField";
import { Button, Grid } from "@mui/material";
import { LABELS as _LABELS } from "../../language";

const LABELS = _LABELS.adminPage;

function RenderItem(props) {
  const item = props.item || {};
  const selected = props.selected || {};
  const name = (item.from && item.from.name) || "";
  const phoneTo = item.phoneTo || "";
  const phoneFrom = item.phoneFrom || "";
  const _message = item.message || "";
  const created = item.created;
  const isImage = item.type === "image";
  let message = _message.slice(0, 60);

  if (_message.length > 60) {
    message = `${message}...`;
  }
  let createdTm = `${props.formatTime(created)}`;

  let datetime = props.formatUsDate(created);
  let readAt = item.readAt
    ? `${props.formatUsDate(item.readAt)} ${props.formatTime(item.readAt)}`
    : "";
  const _readBy = item.readBy || {};

  const readBy = _readBy.name ? `${_readBy.name}` : "";

  if (props.isToday(created)) {
    datetime = props.formatTime(created);
    createdTm = "";
    readAt = item.readAt ? `${props.formatTime(item.readAt)}` : "";
  }
  if (props.isYesterday(created)) {
    datetime = `Yesterday`;
    createdTm = `${props.formatTime(created)}`;
    readAt = item.readAt
      ? `${props.formatUsDate(item.readAt)} ${props.formatTime(item.readAt)}`
      : "";
  }

  const isSelected = item._id === selected._id;
  return (
    <>
      <ListItem
        alignItems="flex-start"
        style={{ backgroundColor: isSelected ? "#f0f0f0" : "" }}
      >
        <Tooltip title={`Reciever ${phoneTo}`} placement="top">
          <ListItemAvatar>
            <Avatar alt={name} src="/static/images/avatar/1.jpg" />
          </ListItemAvatar>
        </Tooltip>
        <ListItemText
          primary={name}
          onClick={() => props.handleClick(item)}
          style={{ cursor: "pointer" }}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "block" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {`${phoneFrom} `}
              </Typography>
              <Typography
                sx={{ display: "block" }}
                style={{ float: "right", marginTop: -45 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {item.read ? (
                  ""
                ) : (
                  <Chip color="primary" size="small" label="NEW" />
                )}
              </Typography>
              <Typography
                sx={{ display: "block" }}
                style={{ float: "right", marginTop: -23, fontSize: 12 }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {`${datetime} `}
                <span
                  style={{ fontSize: 10, textAlign: "right", display: "block" }}
                >
                  {createdTm}
                </span>
              </Typography>
              {isImage ? <ImageIcon /> : message}
              {item.read && (
                <span style={{ fontSize: 10, display: "block" }}>
                  <strong>{`Read: ${readBy} ${readAt}`}</strong>
                </span>
              )}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
}

export default function ChatUser(props) {
  const selected = props.selected || {};
  const items = props.items || [];
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filter, setFilter] = React.useState("All");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const filteredItems = items.filter((item) => {
    const name = (item.from && item.from.name) || "";
    const message = item.message || "";
    const matchesSearchQuery =
      name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      message.toLowerCase().includes(searchQuery.toLowerCase());

    if (filter === "Unread") {
      return matchesSearchQuery && !item.read;
    }

    return matchesSearchQuery;
  });

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" className="header-message">
            {`${LABELS.chat}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ width: "100%", height: "80vh" }}>
        <Grid item xs={10} style={{ borderRight: "1px solid #e0e0e0" }}>
          <Grid item xs={12} style={{ paddingRight: 10 }}>
            <TextField
              sx={{ width: "100%" }}
              id="message-search"
              label={`${LABELS.search}`}
              size="small"
              variant="outlined"
              margin="normal"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: "4px" }}>
            <Chip
              label={`${LABELS.all}`}
              onClick={() => handleFilterChange("All")}
              sx={{
                backgroundColor: filter === "All" ? "#cce4ff" : "default",
                color: filter === "All" ? "#0044cc" : "default",
                marginRight: "4px",
              }}
            />
            <Chip
              label={`${LABELS.unread}`}
              onClick={() => handleFilterChange("Unread")}
              sx={{
                backgroundColor: filter === "Unread" ? "#cce4ff" : "default",
                color: filter === "Unread" ? "#0044cc" : "default",
              }}
            />
          </Grid>
          <Divider />
          {filteredItems.length === 0 && filter === "Unread" && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              style={{ padding: "10px", height: "100%", textAlign: "center" }}
            >
              <Typography variant="body1">{`${LABELS.nounreadchats}`}</Typography>
              <Button
                onClick={() => handleFilterChange("All")}
                style={{ marginTop: "10px" }}
              >
                {`${LABELS.view_all_chats}`}
              </Button>
            </Grid>
          )}
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              maxHeight: props.maxHeight,
              overflow: "scroll",
              bgcolor: "background.paper",
            }}
          >
            {filteredItems.map((item) => (
              <RenderItem
                key={item._id}
                item={item}
                selected={selected}
                handleClick={props.handleClick}
                isToday={props.isToday}
                formatTime={props.formatTime}
                formatUsDate={props.formatUsDate}
                isYesterday={props.isYesterday}
              />
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  );
}
