import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik, Form } from "formik";
import { makeStyles } from '@mui/styles'
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import Error from "../Error";
import ConfirmDialog from "../../components/ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  wrapperButton: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  saveButton: {
    minWidth: 150,
    marginTop: 20,
  },
}));

export default function EditShTrDrDialog(props) {
  const drivers = props.drivers || [];
  const classes = useStyles();
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Update"}</DialogTitle>
      <DialogContent>
        <ConfirmDialog
          open={isOpen}
          label={"Driver action required"}
          agreeLabel={"Accept"}
          disAgreeLabel={"Reject"}
          text={
            "Please handover control to driver to confirm the action. Accept or Reject."
          }
          onClose={(isTrue) => {
            if (isTrue) {
              props.onSave(data);
            }
            setIsOpen(false);
          }}
        />
        <Formik
          initialValues={{ trailer: "", shippingDoc: "" }}
          validate={() => {}}
          onSubmit={(values) => {
            const _data = {};
            setError("");

            if (values.trailer) {
              _data.trailer = values.trailer;
            }
            if (values.shippingDoc) {
              _data.shippingDoc = values.shippingDoc;
            }
            if (values.driverId) {
              _data.driver = drivers.find(
                (_item) => _item.id === values.driverId
              );
            }
            if (_data.shippingDoc || _data.trailer || _data.driver) {
              // props.onSave(data);
              setData(_data);
              setIsOpen(true);
            } else {
              setError("Shipper or trailer or driver is required.");
            }
          }}
          enableReinitialize={true}
        >
          {({ handleSubmit, handleChange }) => {
            return (
              <Form style={{ padding: "20px 10px" }}>
                <Grid container spacing={3}>
                  <InputBox md={6} name="trailer" label="Trailer" />
                  <InputBox md={6} name="shippingDoc" label="Shipping Doc" />
                  <SelectBox
                    md={12}
                    name={"driverId"}
                    label="Driver"
                    selValue={"id"}
                    selLabel={"firstName"}
                    selLabelTwo={"lastName"}
                    selLabelThree={"email"}
                    items={drivers}
                  />
                </Grid>

                {error && <Error message={error} />}
                <div className={classes.wrapperButton}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={props.loading}
                    size="small"
                    className={classes.saveButton}
                  >
                    {props.loading ? "Saving..." : "SAVE"}
                  </Button>
                  <br />
                  <Button variant="link" onClick={props.onClose} size="small">
                    CLOSE
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
