import { _ } from "core-js";
import { isUnity, isEldBooks, isBluehorse,isVulcansols } from "../../configureStore";
let name = "Smartelds";
let website = "https://www.smartelds.com";

if (isUnity) {
  name = "Motion ELD";
  website = "https://motioneld.com";
} else if (isEldBooks) {
  name = "Eldbooks";
  website = "https://eldbooks.com";
} else if (isBluehorse) {
  name = "Bluehorseeld";
  website = "https://bluehorseeld.com";
}else if(isVulcansols){
  name = "Vulcansols ELD";
  website = "http://vulcansols.com";
}

export const LABELS = {
  Enduseragreement: `Acuerdo de usuario final: ${name}`,
  SOFTWARE_AGREEMENT: `Su compra y uso del software ${name} está expresamente sujeto a los términos y condiciones de este acuerdo separado, que puede actualizarse periódicamente, y a su pago puntual de las tarifas mensuales y todas las demás horas aplicables del plan de servicio (si se compra directamente en $ {nombre}). Los términos y condiciones de esta licencia de usuario final pueden cambiar de vez en cuando. Para obtener la versión más reciente que se aplica a usted, visite ${website}, que reemplaza todas las versiones anteriores de este acuerdo, incluidas, entre otras, todas las versiones impresas que acompañaron su compra. Horario de servicio y condiciones:`,
  ImportantReadcarefully: `Importante: lea atentamente: no es necesaria ninguna firma para estar vinculado a este acuerdo. En cambio, al hacer clic en "Acepto" en línea, o al utilizar los servicios definidos a continuación, ofrecidos, comprados, distribuidos o administrados por ${name} o un revendedor autorizado de ${name}, usted (en nombre suyo o como representante autorizado en nombre de otra persona) por la presente acepta todos los términos y condiciones de este acuerdo de licencia de usuario final (el "acuerdo") con ${name} y sus revendedores autorizados con respecto al uso de dicho dispositivo, horas de plan de servicio , cualquier firmware en dicho dispositivo y los servicios. Si no está de acuerdo con todos estos términos, no utilice los servicios y haga clic en el botón "no aceptar" a continuación, o no active su cuenta del plan de horas de servicio. Si no acepta, tenga en cuenta que no podrá utilizar el dispositivo, ni el plan de horas de servicio, ni el firmware del dispositivo ni de los servicios. En este acuerdo, a usted (o "cliente") y a ${name} y sus revendedores autorizados a veces se les denomina individualmente como una "parte" y colectivamente como las "partes". a menos que se defina lo contrario en este acuerdo, todos los términos en mayúscula tendrán el significado que se les atribuye en la sección 1. La fecha de vigencia de este acuerdo es la fecha en que usted acepta este acuerdo al hacer clic en el botón "Acepto" en línea, o la fecha en que De lo contrario, comenzará a utilizar los servicios.`,
  Definition: "1. Definiciones",



  terms_and_condition_Details: `Además de los términos definidos en el cuerpo de este acuerdo, los siguientes términos tienen los siguientes significados:
"información confidencial" significa información no pública que una parte proporciona y considera razonablemente de naturaleza confidencial, de propiedad exclusiva o secreto comercial,
 incluyendo planes de marketing, ingeniería y otros, estados financieros y proyecciones, información de clientes y proveedores, investigaciones, diseños, planes, compilaciones, métodos, técnicas,
  procesos, procedimientos y conocimientos, ya sea en forma tangible o intangible, y estén o no almacenados, compilados o conmemorados física, electrónica, gráfica, fotográfica o por escrito. La información confidencial no incluirá información no confidencial.
"Cliente" o "usted" o "su" significa el titular de la cuenta individual que: 1) tiene plena autoridad legal para celebrar este acuerdo y ha celebrado este acuerdo, ya sea en nombre 
por sí mismo o en nombre de otra persona de conformidad con un poder notarial duradero, otra autorización legal o como tutor legal de dicha persona; y 2) compra o utiliza de otro modo los servicios de acuerdo con los términos y condiciones establecidos en este acuerdo.
"Dispositivo" significa un dispositivo móvil comprado o distribuido por ${name} destinado a ser utilizado por el cliente para rastrear y/o monitorear a los usuarios monitoreados.
"Internet" significa la red informática global compuesta de redes interconectadas que utilizan protocolos estándar, incluidos http, udp y tcp/ip.
"Datos de ubicación" significa el historial de ubicación y la actividad de transacciones que pueden generarse mediante el uso del dispositivo y la hora del plan de servicio y los servicios.
"Usuarios supervisados" significa una persona que es supervisada por el cliente y/o el titular de la subcuenta de conformidad con un acuerdo de consentimiento/liberación.
"Información no confidencial" significa información que: (i) es, a partir de la fecha de su divulgación o posteriormente, parte del dominio público sin culpa de la parte receptora; (ii) 
puede demostrarse mediante evidencia creíble: (a) como legítimamente conocida por la parte receptora antes de la marca de tiempo de su divulgación, o (b) haber sido desarrollada independientemente por la parte receptora; (iii) 
posteriormente se entera de un tercero que no tiene obligación de confidencialidad con la parte divulgadora; o (iv) deba ser divulgado conforme a una citación, orden judicial o autoridad gubernamental debidamente autorizada,
 siempre que la parte receptora haya proporcionado aviso por escrito y asistencia a la parte divulgadora antes de dicha divulgación, de modo que dicha parte pueda solicitar una orden de protección u otro recurso apropiado para protegerse contra la divulgación.
"Marcas comerciales ${name}" significa cualquier nombre, logotipo y marca comercial de ${name} utilizado por ${name} para identificarse a sí mismo o cualquier producto o servicio de ${name} que ${name} le conceda bajo licencia en virtud de este acuerdo.
"Información personal" significa cualquier información que pueda usarse para identificar a un individuo y que de otro modo no esté disponible públicamente. dicha información puede incluir, por ejemplo, el nombre y apellido de una persona combinados con
 su dirección de correo electrónico, número de teléfono móvil o información de cuenta de tarjeta de crédito.
"Servidor" significa el servidor o servidores designados por ${name} de marca de tiempo a marca de tiempo a través del cual ${name} proporciona los servicios de acuerdo con este acuerdo.
"Servicios" significará todos los servicios asociados con horas puntuales de servicio (registros electrónicos), órdenes de trabajo móviles, todo alojamiento y mantenimiento de estas aplicaciones en ${name} o ${name} servicio designado. 
servidores del proveedor de conformidad con los términos y condiciones de este acuerdo y cualquier servicio adicional u opción complementaria que elija. tal como se utiliza en este acuerdo, ${name} incluye ${name} y todos sus proveedores de servicios designados.
"Titular(es) de subcuenta" significa la(s) persona(s) adecuada y válidamente autorizada(s) por un cliente para utilizar los servicios solicitados por el cliente de acuerdo con los términos y condiciones establecidos en este acuerdo.
"Territorio" significa los Estados Unidos de América o cualquier otra área designada aprobada por ${name} aplicable a sus servicios.
"Monitoreo(ed/ing)" significará el uso de horas de plan de servicio para adquirir datos de cada dispositivo.

"Plan de horas de servicio" es un programa de gestión integral ofrecido por ${name} que utiliza una aplicación de software basada en web, impulsada y/o propiedad de ${name} y/o sus representantes designados.
 proveedor de servicio. la aplicación, junto con los dispositivos, recopila y almacena ciertos datos en servidores informáticos. estos datos se ponen a su disposición. El programa del plan de horas de servicio proporciona seguimiento. 
 capacidades de los dispositivos y ayuda a mantener datos sobre personas o activos que utilizan los dispositivos según lo previsto.`,


  Order: "2 órdenes",

  The_terms_and_conditions_of_this_agreement_will_control_all_orders_services: `2.1 Los términos y condiciones de este acuerdo controlarán todos los pedidos de servicios. sujeto a los términos y condiciones de este acuerdo, ${name} acepta brindar acceso a planes de horas de servicio de acuerdo con el primer pedido que envíe a ${name} en la fecha de vigencia.`,


  Access_to_plans_restrictions_on_use_licenses: `3. Acceso a planes con restricciones de uso y licencias.`,

  Access_to_hours_of_service_plan: `3.1 Acceso al plan de horas de servicio. durante la vigencia de este acuerdo y sujeto a los términos y condiciones del presente, ${name} por el presente le otorga una licencia limitada, no exclusiva, intransferible y que genera regalías para acceder a planes de horas de servicio a través de Internet únicamente para el propósito de monitoreo de activos y dispositivos.`,
  Use_by_sub_account_holders: `3.2 Uso por parte de titulares de subcuentas. un cliente puede autorizar a otras personas ("titulares de subcuentas") a utilizar los dispositivos y servicios solicitados por el cliente en virtud de este acuerdo. en tales casos, el cliente deberá asegurarse de que dichos titulares de subcuentas utilicen los dispositivos y servicios de manera que cumpla con todas las leyes aplicables y este acuerdo. El cliente es el único responsable del uso de los dispositivos y servicios por parte de dichos titulares de subcuentas. El cliente acepta exigir que cada titular de subcuenta que utilice la cuenta del cliente cumpla con las obligaciones establecidas en este acuerdo, incluidas, entre otras, las restricciones contenidas en la sección 3.3.`,
  Restrictions_on_use: `3.3 Restricciones de uso; ninguna modificación. usted, los titulares de sus subcuentas y los usuarios monitoreados no deberán participar en ninguno de los siguientes actos (individualmente, un "acto prohibido" y colectivamente, "actos prohibidos"): (i) alterar intencionalmente la seguridad de cualquier ${name}' dispositivos 
o servicios; (ii) acceder a datos no asociados con su cuenta a través de dispositivos o servicios de ${name}; (iii) iniciar sesión en un servidor no autorizado u otra cuenta ${name} que no sea de cliente sin autorización; (iv) intentar sondear, escanear o probar la vulnerabilidad de cualquier servicio de ${name} o violar las medidas de seguridad o autenticación de ${name} o ${name} sin la autorización adecuada;
 (v) intencionalmente inutilizar cualquier parte de los servicios de ${name}; (vi) realizar ingeniería inversa, descompilar, desensamblar o intentar de otro modo descubrir el código fuente o las ideas o algoritmos subyacentes de cualquier servicio de ${name}'; (vii) modificar, traducir o crear trabajos derivados basados ​​en cualquier servicio de ${name}; (viii) alquiler, arrendamiento,
  distribuir, otorgar licencias, sublicenciar, vender, revender, asignar o explotar comercialmente cualquier servicio de ${name}' o poner cualquier servicio de ${name}' a disposición de un tercero de forma distinta a lo contemplado en este acuerdo; (ix) utilizar cualquier servicio de ${name}' 
  para fines de tiempo compartido o de oficina de servicios o de otro modo para el beneficio de un tercero; (x) publicar o revelar a terceros cualquier evaluación de los servicios de ${name}' sin el consentimiento previo por escrito de ${name}'; (xi) eliminar, modificar u ocultar cualquier derecho de autor, marca registrada, patente u otro aviso de propiedad que aparezca en cualquier sitio web de ${name}' o
   en asociación con sus servicios; (xii) crear cualquier enlace a los sitios web de ${name}' o enmarcar o reflejar cualquier contenido contenido o accesible desde los sitios web de ${name}'; o (xiii) utilizar cualquier dato o información obtenida mediante el uso de los dispositivos de ${name}' para cualquier propósito ilegal o en violación de cualquier ley aplicable (incluidas, entre otras, las leyes de privacidad y protección de datos).
    Excepto lo expresamente dispuesto en este acuerdo, no se otorga ningún derecho o licencia en virtud del presente, por implicación, impedimento legal o de otro modo.`,
  Firmwarelicense: `3.4 Licencia de firmware. Cada dispositivo proporcionado por ${name} puede contener firmware desarrollado y de propiedad de ${name}. 
para el firmware desarrollado y propiedad de ${name}, o autorizado de otro modo para ser utilizado, ${name} por el presente le otorga un derecho limitado, no exclusivo, intransferible, 
Licencia libre de regalías, por un plazo, para usar el firmware en cada dispositivo. ${name} conservará todos los derechos sobre el firmware contenido en cada dispositivo. la licencia otorgada en este documento se considerará 
entrará en vigor en el momento de la entrega de cada dispositivo.`,
  Ownership: `3.5 Propiedad. usted reconoce que, entre usted, los titulares de sus subcuentas y ${name}, todos los derechos, títulos e intereses en las horas de 
plan de servicio, cualquier software, dispositivo o firmware proporcionado bajo este acuerdo, y todas las modificaciones y mejoras del mismo, incluidos todos los derechos bajo 
Los derechos de autor, patentes y otros derechos de propiedad intelectual pertenecen a ${name} o a la entidad que autorizó a ${name} dicho uso. Este acuerdo no le proporciona el título ni la propiedad del plan de horas de servicio, ningún servicio, ningún 
software, dispositivos o firmware proporcionados en virtud de este acuerdo, pero solo los derechos especificados en este acuerdo.`,


  Service: `4. Servicios`,

  Availabilityofservices: `4.1 Disponibilidad de servicios. Sujeto a los términos y condiciones de este acuerdo, ${name} hará todos los esfuerzos comercialmente razonables para que los servicios estén disponibles las veinticuatro (24) horas del día, los siete (7) días de la semana. usted acepta que, de una marca de tiempo a otra, los servicios pueden ser inaccesibles o inoperables por razones fuera del control de ${name}, incluidos, entre otros: (i) mal funcionamiento del equipo; (ii) procedimientos de mantenimiento periódico o reparaciones que ${name} pueda realizar; o (iii) interrupción o falla de enlaces de telecomunicaciones o transmisión digital, ataques hostiles a la red, congestión de la red u otras fallas. no tendrá derecho a ninguna compensación, descuento, reembolso u otro crédito como resultado de la falta de disponibilidad de los servicios. usted acepta que ${name} no tiene control de la disponibilidad de los servicios de forma continua o ininterrumpida. ${name} no garantiza ninguna tasa de respuesta específica (ancho de banda). cualquier indisponibilidad de los servicios le será comunicada lo antes posible.`,
  Security: `4.2 Seguridad. ${name} hará esfuerzos comercialmente razonables para evitar el acceso no autorizado a áreas restringidas de planes de horas de servicio y cualquier base de datos u otro material confidencial; y ${name} le notificará sobre cualquier violación de seguridad conocida en sus sistemas que tenga una probabilidad razonable de afectarle negativamente a usted o a su cuenta, de conformidad con la ley aplicable.`,
  hoursofserviceplansupport: `4.3 ${name} horas de soporte del plan de servicio. El soporte técnico al cliente para problemas con la aplicación de software del plan de horas de servicio, así como la facturación y cualquier otra pregunta general, será proporcionado por ${name} o ${name}' revendedores autorizados durante el horario comercial razonable y designado de lunes a viernes ( excluyendo los días festivos federales de EE. UU.). Los horarios de soporte y los números de contacto se enumeran en el manual del usuario del producto.`,


  Customerdata: "5. Datos del cliente",

  useofcustomerdata: `5.1 Uso de los datos del cliente por parte de ${name}. ${name} recopila información personal e información no personal sobre sus clientes y titulares de subcuentas (colectivamente, "datos del cliente") para: (i) proporcionar los servicios; (ii) proporcionar soporte técnico y al cliente; y (iii) para otros fines relacionados con el negocio, como facturación, marketing y ofertas, presentaciones y ventas de nuevos servicios. ${name} puede eliminar de sus datos cualquier información personal o basada en la ubicación y combinar dichos datos anónimos con los de otros para crear "información agregada". ${name} puede usar y vender datos agregados, incluido el análisis de cómo los clientes usan los servicios y determinar formas en que ${name} puede mejorar los servicios. Si ha proporcionado su información de contacto, ${name} puede comunicarse con usted con fines de marketing por diversos medios, incluidos, entre otros, correo postal, correo electrónico o teléfono. cuando activa una cuenta de ${name}, acepta expresamente recibir comunicaciones de marketing por correo directo, correo electrónico (a la dirección de correo electrónico que proporcionó al activar su cuenta), teléfono (al número que proporcionó al activar su cuenta), mensajes de texto (si proporcionó un número de teléfono inalámbrico), mensajes instantáneos u otros métodos de comunicación. puede optar por no recibir uno o más de estos tipos de comunicaciones de marketing en cualquier momento comunicándose con el servicio de atención al cliente.`,
  Disclosuresofcustomerdata: `5.2 Divulgaciones de datos de clientes. Si compró los servicios como resultado de los esfuerzos de marketing de un socio de marketing de ${name}, acepta que ${name} pueda revelar sus datos de cliente a dicho socio de marketing.`,
  Retentionofcustomerdata: `5.3 Conservación de los datos del cliente. Los datos del cliente se conservarán siempre que exista una necesidad comercial legítima para hacerlo. por ejemplo, ${name} puede conservar los datos del cliente mientras dure una disputa con nuestra empresa. Los datos de cada cliente se conservarán durante un período que ${name} decidirá a su exclusivo criterio y se pondrán a disposición de las autoridades policiales y otras agencias gubernamentales de conformidad con solicitudes legalmente válidas de dicha información.`,


  Customerresponsibilities: "6. Responsabilidades del cliente",
  Use_of_services_you_acknowledge: `6.1 Uso de los servicios. usted reconoce y acepta que no utilizará los servicios para: (i) monitorear a ningún individuo sin el permiso previo, expreso y legal de dicho individuo, a menos que dicho individuo sea un menor con quien tenga una relación de tutor legal; (ii) enviar spam o mensajes no solicitados en violación de las leyes aplicables; (ii) enviar o almacenar material infractor, obsceno, amenazante, calumnioso o de otro modo ilegal o ilícito, incluido material perjudicial para los niños o que viole los derechos de privacidad de terceros; (iii) enviar mensajes a listas de correo electrónico, listas de distribución, grupos de noticias o direcciones de correo electrónico no deseado; o (iv) enviar o almacenar material que contenga código malicioso, incluidos, entre otros, virus de software, gusanos, troyanos u otros códigos, archivos, scripts, agentes o programas informáticos dañinos. usted acepta informar inmediatamente a ${name} y hacer todo lo posible para detener de inmediato cualquier violación de los términos y condiciones establecidos en esta sección o cualquiera de las otras políticas publicadas de ${name}, incluidas, entre otras, a cualquier política de privacidad (colectivamente, "políticas") que pueda emitirse de una marca de tiempo a otra, todas las cuales se incorporan al presente documento como referencia. En caso de sospecha de violación de los términos, condiciones y restricciones establecidas en esta sección o cualquier política de ${name}, ${name} puede desactivar inmediatamente su acceso a los servicios y suspender la prestación de dichos servicios.`,
  Access_to_hosted_services: `6.2 Acceso a servicios alojados. ${name} le proporcionará un nombre de usuario, contraseña y otra información necesaria para acceder al servicio del plan de horas de servicio. usted es responsable de mantener la confidencialidad de los respectivos nombres de usuario y contraseñas y de cualquier comunicación o transacción realizada utilizando el nombre de usuario y la contraseña. usted es responsable de cambiar los respectivos nombres de usuario y contraseña si se cree que han sido robados o podrían usarse indebidamente.`,
  Equipment_and_utilities: `6.3 Equipos y servicios públicos. usted es responsable y asumirá los costos asociados con el suministro y mantenimiento del acceso a Internet y todos los equipos de telecomunicaciones, software y otros materiales necesarios para acceder a su plan de horas de servicio.`,
  Consents_you_be_responsible_for_obtaining_the_necessary: `6.4 Consentimientos. usted será responsable de obtener los consentimientos legales necesarios de aquellos usuarios monitoreados que están siendo monitoreados por su uso y el uso de los dispositivos por parte de los titulares de su subcuenta y deberá autorizar el monitoreo de dichos dispositivos por parte de $ {nombre} y sus subcontratistas. Si un usuario monitoreado es menor de edad, usted reconoce que usted y/o el titular de su subcuenta tienen la tutela legal sobre dicho menor y además reconoce que usted y/o el titular de su subcuenta tienen el derecho legal de monitorear legalmente a dicho usuario monitoreado. en la ubicación o ubicaciones donde se utilizan los dispositivos. usted acepta indemnizar y eximir a ${name} sus agentes, licenciatarios, licenciantes y subcontratistas de todos y cada uno de los costos y gastos relacionados con cualquier reclamo que surja del monitoreo no autorizado de los dispositivos por parte de usted o su subcontratista. los titulares de cuentas.`,
  Credit_you_shall_cooperate_with_the_timely_completion_ofthe_credit_application: `6.5 Crédito. deberá cooperar con la finalización oportuna de la solicitud de crédito proporcionada por ${name} con el fin de establecer un límite de crédito para facturas pendientes si compró los servicios de ${name}. usted acepta notificar a ${name} sobre cualquier cambio en el estado del crédito durante el transcurso de este acuerdo y puede solicitar una revisión del límite de crédito en cualquier momento. usted comprende que ${name} puede establecer un límite de crédito antes del envío de dispositivos y/o el inicio de los servicios.`,
  Carrier_customer_expressly_understands_and_agrees: `6.6 Transportista. El cliente entiende y acepta expresamente que no tiene relación contractual alguna con el proveedor de servicios inalámbricos subyacente o sus afiliados o contratistas y que el cliente no es un tercero beneficiario de ningún acuerdo entre el cliente y el proveedor subyacente. Además, el cliente reconoce y acepta que el transportista subyacente y sus afiliados y contratistas no tendrán ninguna responsabilidad legal, equitativa o de otro tipo ante el usuario final y el usuario final renuncia a todos y cada uno de los reclamos o demandas al respecto".`,


  Disclaimer: `Descargo de responsabilidad`,
  Although_has_attempted_to_provide_accurate_information_on_this_web_site: `Aunque ${name} ha intentado proporcionar información precisa en este sitio web, la información contenida en este sitio web puede contener imprecisiones técnicas, errores tipográficos o de otro tipo. Se cree que el contenido de cualquier documento en este sitio web es actual y preciso en la fecha de su publicación. ${name} no asume ninguna responsabilidad por la exactitud de la información. ${name} puede cambiar los productos y otros materiales mencionados en cualquier momento sin previo aviso. La mención de productos o servicios que no son de ${name} tiene fines informativos únicamente y no constituye ni un respaldo ni una recomendación.`,

  All_information_software_products_and_services_provided: `Toda la información, software, productos y servicios proporcionados en este sitio web se proporcionan "tal cual" sin garantía de ningún tipo, ya sea expresa, implícita, legal o de otro tipo. ${name} renuncia a todas las garantías, expresas o implícitas, incluidas, entre otras, las de comerciabilidad, idoneidad para un propósito particular y no infracción o que surjan de un curso de negociación, uso o práctica comercial. Algunas jurisdicciones no permiten la exclusión de garantías implícitas, por lo que es posible que la exclusión anterior no se aplique a usted.`,

  shall_not_be_liable_for_any_indirect_special_consequential: `${name} no será responsable de ningún daño indirecto, especial, consecuente o incidental, incluidos, entre otros, pérdida de ganancias o ingresos, costos de reemplazo de bienes, pérdida o daño a los datos que surjan del uso o la imposibilidad de usar este sitio web. sitio o cualquier producto de ${name}, o daños resultantes del uso o confianza en la información presente, incluso si ${name} ha sido advertido de la posibilidad de tales daños.`,






  term_and_condition: "Términos y condiciones",
  loading: "Cargando...",
  accept_and_continue: "Aceptar y continuar",
  decline: "Rechazar",
  please_try_after_sometime: "Inténtalo después de un tiempo.",
  not_able_to_be_continue: "No puedo continuar porque no se aceptan términos y condiciones."

}