import {LABELS as _LABELS} from "../../language";
 const LABELS =_LABELS.settingPage;

const defaultValues = {
  resellerId: "",
  status: "NEW",
  channel: "REFERRAL",
  dot: "",
  callback: "null",
  description: "",
  carrier: {},
  cbDate: "",
  contact: {},
  assignedTo: { name: "", email: "", phone: "" },
};

const validate = (values) => {
  let errors = {};
  const requiredFields = ["dot", "status", "callback","cbDate"];

  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = LABELS.required;
    }
  });

  return errors;
};

export { validate, defaultValues };
