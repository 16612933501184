import _get from "lodash/get";
import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
// import { validate, defaultValues } from "./validate";
import Grid from '@mui/material/Grid';
import SelectBox from "../../components/SelectBox";
import AdminAccountSearch from "../../components/AdminAccountSearch";
import Error from "../Error";
import Success from "../Success";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from '@mui/styles';
import { getAllUsers } from "../../actions/adminDashActions";
import { getAccounts } from "../../actions/account/accountAdminActions";
import {LABELS as _LABELS} from "../../language"
const LABELS = _LABELS.adminPage;


const styles = (theme) => ({
  addButtonWrapper: {
    width: "99%",
    textAlign: "right",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
    textAlign: "left",
  },
  grow: {
    flexGrow: 1,
    textAlign: "center",
  },
  buttonGroup: {
    marginTop: 25,
    textAlign: "center",
  },
});

export function SearchAppBar(props) {
  const [drivers, setDrivers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  // const classes = props.classes;
  const constants = props.constants;
  const { errorMessage, successMessage, loading } = {};

  let initialValues = {};

  function validate() {}

  function handleSubmit(values) {
    props.onSearch(values);
  }

  async function _getAccounts() {
    try {
      const _accounts = await props.getAccounts({});
      setAccounts(_accounts);
    } catch (e) {
      console.log(e);
    }
  }

  async function _getDrivers(accountId) {
    try {
      const _drivers = await props.getAllUsers({
        accountId,
        skip: 0,
        limit: 500,
      });
      setDrivers(_drivers);
    } catch (e) {
      console.log(e);
    }
  }

  const [visible, setVisible] = useState(true);
  function forceRerender() {
    setVisible(false);
    setTimeout(function () {
      setVisible(true);
    }, 10);
  }

  useEffect(() => {
    _getAccounts();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        return (
          <Form style={{ padding: "20px 10px" }}>
            <Grid container spacing={3}>
              {visible && (
                <AdminAccountSearch
                  md={3}
                  selValue={"_id"}
                  selLabel={"name"}
                  name="accountId"
                  label={`${LABELS.accounts}`}
                  items={accounts}
                  selectedValue={values.accountId}
                  onChange={(e) => {
                    if (e && e._id) {
                      const _aId = e._id;
                      _getDrivers(_aId);
                      setFieldValue("accountId", e._id);
                    }
                  }}
                />
              )}
              <SelectBox
                md={3}
                selValue={"id"}
                selLabel={"firstName"}
                selLabelTwo={"lastName"}
                selLabelThree={"status"}
                name="driverId"
                label={`${LABELS.driver}`}
                items={drivers}
              />
              <SelectBox
                md={3}
                name="callStatus"
                label={`${LABELS.status}`}
                items={constants.SUPPORT_CALL_STATUS}
              />
              <Grid item  sm xs={12} md={3}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                >
                  {`${loading ? LABELS.saving : LABELS.search}`}
                </Button>{" "}
                <Button
                  variant="contained"
                  onClick={() => {
                    setFieldValue("accountId", "");
                    setFieldValue("driverId", "");
                    setFieldValue("callStatus", "");
                    forceRerender();
                    props.onSearch({});
                  }}
                >
                  {`${LABELS.clear}`}
                </Button>{" "}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={props.handleAddClick}
                >
                  {`${LABELS.new}`}
                </Button>{" "}
              </Grid>
            </Grid>

            {successMessage && <Success message={successMessage} />}
            {errorMessage && <Error message={errorMessage} />}
          </Form>
        );
      }}
    </Formik>
  );
}

const mapStateToProps = (state) => {
  return {
    drivers: _get(state, "driverData.drivers", []),
    equipments: _get(state, "equipmentData.equipments", []),
    accounts: _get(state, "accountData.accounts", []),
    constants: _get(state, "appData.constants", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAccounts,
      getAllUsers,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SearchAppBar)
);
